import React, { useState, useEffect } from "react";
import { dispatch } from "store/index";
import {
    FetchAllSMSTemplates,
    StartActivityProcess,
    FetchSmSSettings,
    FetchCallActivity,
    FetchEditActivity,
    FetchActivityDetails,
} from "store/action/index";
import { useLocation, useNavigate } from "react-router-dom";
import MainCard from "components/MainCard";
import { Table, Button, Select, Typography, Form, Input } from "antd";
import { connect } from "react-redux";
import AddLeadsDrawer from "./AddLeadDrawer";
import { IoMdAddCircle } from "react-icons/io";
import { toast } from 'react-toastify';
import { EditActivityLeadsLogs } from "components/columns/columns";
import axios from 'axios';
import * as url from "../../store/constant/Endpoints"
const CryptoJS = require("crypto-js");

const { Option } = Select;
const { Title } = Typography;
const { TextArea } = Input;

const SMSActivityEdit = ({
    allsmsTemplates,
    allSmssetting,
    activityCall,
    activityDetail,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const initialData = location?.state?.data || {};
    const initialstaus = initialData?.status || 0;
    const [STATUS, setStatus] = useState(initialstaus);


    const [templateOptions, setTemplateOptions] = useState([]);
    const [isProgrammaticUpdate, setIsProgrammaticUpdate] = useState(false);
    const [hasFormBeenModified, setHasFormBeenModified] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(
        initialData?.template_id || null
    );
    const [selectedProvider, setSelectedProvider] = useState(
        initialData?.setting_id || null
    );
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [loadingBtnStart, setLoadingBtnStart] = useState(false)
    const [EditActivityTemplate, setEditActivityTemplate] = useState({});
    const [leadsDataSource, setLeadsDataSource] = useState([]);

    useEffect(() => {
        setTemplateOptions(allsmsTemplates);
    }, [allsmsTemplates]);

    useEffect(() => {
    }, [allSmssetting])

    const createNewTemplate = async () => {
        try {
            const values = await form.validateFields();
            const payload = {
                name: values.name,
                body: values.body,
            };

            return new Promise(async (resolve, reject) => {
                try {
                    let getNewTokenn = localStorage.getItem("token");
                    let NewToekn = "";
                    if (getNewTokenn) {
                        NewToekn = CryptoJS.AES.decrypt(
                            getNewTokenn,
                            url.encryptionSecret
                        ).toString(CryptoJS.enc.Utf8);
                    }
                    const config = {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Bearer ${NewToekn}`,
                        },
                    };

                    const response = await axios.post(
                        `${url?.base_url}${url.sms_templates_api}`,
                        payload, config
                    );

                    const responseData = response.data;
                    if (responseData) {
                        resolve(responseData.data?.id);

                    }
                } catch (error) {
                    console.error('Error creating new template:', error);
                    reject(error);
                }
            });
        } catch (error) {
            console.error('Form validation failed:', error);
            throw error;
        }
    };

    // useEffect(() => {
    //     if (activityCall) {
    //         setLeadsDataSource([
    //             ...(location?.state?.data?.leads || []),
    //             ...(activityCall?.data?.[0]?.leads || [])
    //         ]);
    //     }
    //     console.log("textAlignTextFLow", activityCall?.data?.[0]?.leads || []);
    // }, [activityCall]);


    useEffect(() => {
        if (activityCall?.data?.data?.[0]?.leads) {
            const initialLeads = initialData?.leads || [];
            const activityLeads = activityCall?.data?.data[0]?.leads || [];

            setLeadsDataSource([...initialLeads, ...activityLeads]);
            // console.log("activeCallCheckInitailData", activityCall)
        }
    }, [activityCall, initialData?.leads]);


    const handleTemplateChange = (value) => {
        // console.log("CHwkxingTEstt", value)
        setSelectedTemplate(value);
        const selectedTemp = templateOptions?.find(temp => temp.id === value);
        if (selectedTemp) {
            setIsProgrammaticUpdate(true);
            const formValues = {
                body: selectedTemp.body || "",
                name: selectedTemp.name || "",
            };
            form.setFieldsValue(formValues);
            setTimeout(() => setIsProgrammaticUpdate(false), 0);
        }
    };

    useEffect(() => {
        if (selectedTemplate && templateOptions?.length !== 0) {
            let filterTemplate = templateOptions?.filter(
                (e) => e?.id === selectedTemplate
            );
            setEditActivityTemplate(filterTemplate[0]);
        }
    }, [selectedTemplate, templateOptions]);

    useEffect(() => {
        // Fetch initial data
        const fetchData = async () => {
            try {
                await Promise.all([
                    dispatch(FetchAllSMSTemplates()),
                    dispatch(FetchSmSSettings())
                ]);

                if (initialData?.activity_id_specific) {
                    const params = { id: initialData.activity_id_specific };
                    dispatch(FetchCallActivity({ params }));
                }
            } catch (error) {
                console.error('Error fetching initial data:', error);
                toast.error('Failed to load data. Please try again.');
            }
        };

        fetchData();
    }, [initialData?.activity_id_specific]);

    useEffect(() => {
        if (templateOptions?.length !== 0 && initialData?.template_id) {
            handleTemplateChange(initialData?.template_id)
        }
        // console.log("AllObjectNeverEvenNeverTightEights", initialData)
    }, [templateOptions, initialData?.template_id]);

    const HandleSave = async (status_id) => {
        try {
            if (status_id === 1 && !selectedProvider) {
                toast.error("Provider must be selected");
                return;
            }

            let finalTemplateId = selectedTemplate;

            if (selectedTemplate && hasFormBeenModified) {
                const newTemplateId = await createNewTemplate();
                finalTemplateId = newTemplateId;
                await Promise.all([
                    dispatch(FetchAllSMSTemplates()),
                    dispatch(FetchSmSSettings())
                ]);
            }

            if (!selectedTemplate && hasFormBeenModified) {
                const newTemplateId = await createNewTemplate();
                finalTemplateId = newTemplateId;

                await Promise.all([
                    dispatch(FetchAllSMSTemplates()),
                    dispatch(FetchSmSSettings())
                ]);
            }

            if (selectedTemplate && !hasFormBeenModified || !selectedTemplate && !hasFormBeenModified) {
                const payload = {
                    activity_id: initialData?.activity_id_specific,
                    campaign_id: initialData?.campaign_id,
                    template_id: finalTemplateId,
                    setting_id: selectedProvider,
                    status: status_id,
                    body: form.getFieldValue('body'),
                    name: form.getFieldValue('name'),
                };
                setLoadingBtn(true)
                setStatus(1)
                dispatch(FetchEditActivity({ fieldData: payload, setLoadingBtn: setLoadingBtn }));
            }
        } catch (error) {
            toast.error("Please fill out all required fields");
        }
    };

    const handleNavigate = () => {
        navigate(-1);
    };

    const getActivityHistory = () => {
        if (activityCall?.data?.data && Array.isArray(activityCall?.data?.data) && activityCall?.data?.data?.length > 0) {
            return activityCall.data?.data[0]?.activity_history || [];
        }
        return [];
    };

    const ProccedHitApi = () => {
        setLoadingBtnStart(true)
        dispatch(StartActivityProcess({ activity_id: initialData?.records?.id, setLoadingBtnStart: setLoadingBtnStart, isNavigate: handleNavigate }));
    }

    // console.log("FunctiongaianDta", getActivityHistory)
    return (
        <MainCard>
            <div>
                <h3 style={{ marginBottom: "20px" }}>Edit SMS Activity</h3>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ justifyContent: "left" }}>
                        <AddLeadsDrawer
                            leadsDataSource={leadsDataSource}
                            datacampaign={initialData}
                            handleNavigate={handleNavigate}
                            status={STATUS}
                        >
                            <Button
                                style={{
                                    backgroundColor: "#0055A2",
                                    color: "#fff",
                                    fontFamily: "poppins",
                                    fontSize: "13px",
                                }}
                                size="large"
                            >
                                <IoMdAddCircle color="#fff" size={24} />
                                Add Leads
                            </Button>
                        </AddLeadsDrawer>
                    </div>


                    <div style={{ display: "flex", gap: 10 }}>
                        <div className="count-box">
                            <span>Total: </span>
                            {activityCall?.data?.data?.[0]?.activity_history?.length || 0}
                        </div>
                        <div className="count-box">
                            <span>New: </span>
                            {activityCall?.data?.data?.[0]?.leads?.length || 0}
                        </div>

                    </div>
                </div>

                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        body: EditActivityTemplate?.body || '',
                        name: EditActivityTemplate?.name || '',
                    }}
                    onValuesChange={(changedValues, allValues) => {
                        if (!isProgrammaticUpdate && (changedValues.name || changedValues.body)) {
                            setHasFormBeenModified(true);
                        }
                    }}

                >
                    <div style={{ marginTop: 30 }}>
                        <div style={{ display: "flex", gap: 20 }}>

                            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column' }}>
                                <label style={{ marginBottom: 5, fontSize: 13 }}>Select Template</label>
                                <Select
                                    placeholder="Select Template"
                                    style={{ width: 300 }}
                                    value={selectedTemplate}
                                    disabled={STATUS === 1 || STATUS === 3 || STATUS === 4}
                                    onChange={handleTemplateChange}
                                >
                                    {Array.isArray(templateOptions) &&
                                        templateOptions.map((template) => (
                                            <Option key={template.id} value={template.id}>
                                                {template.name}
                                            </Option>
                                        ))}
                                </Select>
                            </div>

                            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column' }}>
                                <label style={{ marginBottom: 5, fontSize: 13 }}>Select Provider</label>
                                <Select
                                    placeholder="Provider"
                                    style={{ width: 300 }}
                                    value={selectedProvider}
                                    disabled={STATUS === 1 || STATUS === 3 || STATUS === 4}
                                    onChange={(v) => setSelectedProvider(v)}
                                >
                                    {Array.isArray(allSmssetting?.settings) && allSmssetting.settings.map((setting) => (
                                        <Option key={setting.id} value={setting.id}>
                                            {setting.name}
                                        </Option>
                                    ))}


                                </Select>
                            </div>
                        </div>

                        <Form.Item
                            label="Name"
                            name="name"
                        >
                            <Input placeholder="Enter Name" disabled={STATUS === 1 || STATUS === 3 || STATUS === 4} />
                        </Form.Item>
                        <Form.Item
                            name="body"
                            label="Body"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter SMS message',
                                },
                            ]}
                        >
                            <TextArea
                                rows={4}
                                disabled={STATUS === 1 || STATUS === 3 || STATUS === 4}
                                showCount
                                placeholder="Enter your SMS message here"
                            />
                        </Form.Item>
                    </div>
                </Form>
                <div style={{ display: "flex", justifyContent: "end", gap: 30, marginTop: 20 }}>

                    {(STATUS == 0 || STATUS == 2) && (
                        <>
                            <Button
                                style={{
                                    backgroundColor: "#0055A2",
                                    color: "#fff",
                                    fontFamily: "poppins",
                                    fontSize: "13px",
                                    minWidth: "150px",
                                }}
                                onClick={() => HandleSave(1)}
                                size="large"
                                loading={loadingBtn}
                            >
                                Save
                            </Button>
                            <Button
                                style={{
                                    backgroundColor: "#0055A2",
                                    color: "#fff",
                                    fontFamily: "poppins",
                                    fontSize: "13px",
                                    minWidth: "150px",
                                }}
                                onClick={() => HandleSave(2)}
                                size="large"
                                loading={loadingBtn}
                            >
                                Save as Draft
                            </Button>
                        </>

                    )}
                    {STATUS == 1 ? <Button
                        style={{
                            backgroundColor: "#0055A2",
                            color: "#fff",
                            fontFamily: "poppins",
                            fontSize: "13px",
                        }}
                        size="large"
                        loading={loadingBtnStart}
                        onClick={ProccedHitApi}
                    >
                        Start
                    </Button> : <></>}
                </div>

                <Table
                    className="border rounded"
                    style={{ marginTop: 25 }}
                    dataSource={getActivityHistory()}
                    columns={EditActivityLeadsLogs()}
                />
            </div>
        </MainCard>
    );
};

const mapStateToProps = (state) => ({
    allsmsTemplates: state?.AllSmsTemplatesReducer?.allsmsTemplates,
    activityDetail: state?.ActivityDetailRedc?.activityDetails,
    activityCall: state?.ActivityCallRedc?.activityCall,
    allSmssetting: state.AllSmsDataReducer.allSmssetting,

});

export default connect(mapStateToProps)(SMSActivityEdit);