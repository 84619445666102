// type
import { ShopRemove } from "iconsax-react";
import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";
import { MdHome } from "react-icons/md";
import { BsBuildingFillAdd } from "react-icons/bs";
import { TbRulerMeasure } from "react-icons/tb";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { TbHomeCog } from "react-icons/tb";
import { MdOutlineHomeWork } from "react-icons/md";
import { MdOutlineBedroomParent } from "react-icons/md";
import { MdOutlineSecurity } from "react-icons/md";
import { IoIosCall } from "react-icons/io";
import { HiUsers } from "react-icons/hi2";
import { MdOutlineSms } from "react-icons/md";
import {
  can_add_users,
  can_view_campaign_activitiess,
  can_view_users,
} from "helper/permissionsCheck";
import DrawerRight from "components/drawer/AddCampaignDrawer";
import { Typography } from '@mui/material'
// icons
const icons = {
  navigation: MdHome,
  flatType: TbHomeCog,
  amenities: BsBuildingFillAdd,
  flatSize: TbRulerMeasure,
  taxes: HiOutlineReceiptTax,
  flat: MdOutlineBedroomParent,
  assignPermission: MdOutlineSecurity,
  color: MdHome,
  shadow: MdHome,
  AllUsers: HiUsers,
  calllogs: IoIosCall,
  smslogs: MdOutlineSms,
};

const flat =
  can_view_campaign_activitiess
    ? {
      id: "ddall-campaigns",
      title: "Campaigns",
      icon: icons.AllUsers,
      type: "group",
      children: [
        can_view_campaign_activitiess
          ? {
            id: "all-campaigns",
            title: "All Campaigns",
            type: "item",
            url: "/campaigns/all-campaigns",
            icon: icons.AllUsers,
            breadcrumbs: true,
          }
          : null,
      ].filter(Boolean),

    }
    : null;

export default flat;
