import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";
export const isAuthenticated = (err) => {
  if (err == "Unauthenticated.") {
    localStorage.clear();
    window.location = "/";
    window.location.reload();
  }
};

export const toastStyle = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  bodyClassName: "toastStyle",
  style: {
    zIndex: 999999999, // Adding high z-index
  },
  toastClassName: "toast-z-index",
};

export const getDecryptedUserData = () => {
  let permissionUser = localStorage.getItem("data");

  let decryptedData = null;
  if (permissionUser) {
    try {
      // Code that may throw an exception
      decryptedData = AES.decrypt(
        permissionUser,
        url.encryptionSecret
      ).toString(enc.Utf8);
      // ...
    } catch (error) {
      // localStorage.clear();
      // window.location.reload();
    }
  }

  return decryptedData;
};
