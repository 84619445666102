import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import countryTelephoneData from 'country-telephone-data';

const CountryCodeDropdown = ({ onSelect, defaultValue }) => {
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    const options = countryTelephoneData.allCountries.map((country) => ({
      value: `+${country.dialCode}`,
      label: `+${country.dialCode}${country.name}`,
    }));
    setCountryOptions(options);
  }, []);

  useEffect(() => {
    if (defaultValue) {
      const options = countryTelephoneData.allCountries.find((country) => `${defaultValue.split(' ')[0]}`.includes(country.dialCode));
      console.log()
      setSelectedCountry({
        value: `+${options?.dialCode}`,
        label: `+${options?.dialCode}${options?.name}`,
      })
      setPhoneNumber(defaultValue.split(' ')[1]);

    }
  }, [defaultValue])

  const handleCountryChange = (option) => {
    setSelectedCountry(option);
    setPhoneNumber(`${option.value} `);
  };


  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;
    setPhoneNumber(value);
    sendFormattedNumber(`${selectedCountry?.value} ${value}`);
  };

  const sendFormattedNumber = (formattedNumber) => {
    onSelect({ value: formattedNumber, label: formattedNumber });
  };

  return (
    <>

      <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
        <Select
          value={selectedCountry}
          onChange={handleCountryChange}
          options={countryOptions}
          placeholder="Select a country code"
          style={{ flex: 1, maxWidth: '150px' }}
        />
        <input
          type="number"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          placeholder="Phone Number"
          style={{
            flex: 1,
            padding: '8px',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
        />
      </div>

      <span style={{ color: 'red' }}>Don't Include 0 in your number</span>

    </>

  );
};

export default CountryCodeDropdown;