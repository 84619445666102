import React, { useEffect, useState } from 'react';
import { Card, Avatar, Progress, Typography, Row, Col, Tooltip, Modal } from 'antd';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UserOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import DateTimeFormatter from '../../dateformat';
import { getDecryptedUserData } from '../../../helper/helper';
import MultiProgress, { ProgressComponentProps } from "react-multi-progress";

// for non-TS projects, remove this and other types


function CustomComponent({ children, element, ...rest }) {
  return (
    <div
      {...rest}
      style={{
        fontWeight: element.isBold ? 900 : 300,
      }}
    >
      {children}
    </div>
  );
}

function ProgressBar({ campaign }) {
  // Filter the statuses
  const call_done = campaign?.activity_history.filter((v) => v?.status === "1");
  const call_remain = campaign?.activity_history.filter((v) => v?.status === "0");
  const call_followup = campaign?.activity_history.filter((v) => v?.status === "2");
  const call_inprocess = campaign?.activity_history.filter((v) => v?.status === "3");

  // Total leads
  const total_leads = campaign?.activity_history.length || 0;

  // Calculate percentages
  const donePercent = total_leads ? (call_done.length / total_leads).toFixed(4) * 100 : 0;
  const remainPercent = total_leads ? (call_remain.length / total_leads).toFixed(4) * 100 : 0;
  const followupPercent = total_leads ? (call_followup.length / total_leads).toFixed(4) * 100 : 0;
  const inprocessPercent = total_leads ? (call_inprocess.length / total_leads).toFixed(4) * 100 : 0;

  return (
    <div>
      {/* Progress Bar */}
      <MultiProgress
        transitionTime={1.2}
        elements={[
          {
            value: donePercent,
            color: "#66C47C", // Soft but noticeable green
            showPercentage: true,
            textColor: "white", // Dark green for contrast
            fontSize: 10,
          },
          {
            value: remainPercent,
            color: "#F27979", // Soft coral red
            showPercentage: true,
            textColor: "white", // Deep maroon for contrast
            fontSize: 10,
          },
          {
            value: followupPercent,
            color: "#FFB347", // Soft amber orange
            showPercentage: true,
            textColor: "white", // Rich brown-orange for readability
            fontSize: 10,
          },
          {
            value: inprocessPercent,
            color: "#5A9BD8", // Medium pastel blue
            showPercentage: true,
            textColor: "white", // Deep navy blue for clarity
            fontSize: 10,
          },
        ]}


        height={15}
        backgroundColor="lightgray"

        component={CustomComponent}
      />

      {/* Legend */}
      <div style={{ marginTop: "10px", display: 'flex', gap: '10px' }}>
        <div>
          <span >Done:</span> <span style={{ color: "green", fontWeight: "bold" }}>{call_done.length}/{total_leads}</span>
        </div>
        <div>
          <span >Remaining:</span> <span style={{ color: "red", fontWeight: "bold" }}>{call_remain.length}/{total_leads}</span>
        </div>
        <div>
          <span >Follow-Up:</span> <span style={{ color: "orange", fontWeight: "bold" }}>{call_followup.length}/{total_leads}</span>
        </div>
        <div>
          <span >In-Process:</span> <span style={{ color: "blue", fontWeight: "bold" }}>{call_inprocess.length}/{total_leads}</span>
        </div>
      </div>
    </div>
  );
}





const CampaignCard = ({ campaign }) => {
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleResumeClick = () => {
    setShowConfirmation(true);
  };

  // const handleConfirmation = (confirmed) => {
  //   setShowConfirmation(false);
  //   if (confirmed) {
  //     navigate('/activities/call-leads');
  //   }
  // };



  const handleConfirmation = (confirmed) => {
    setShowConfirmation(false);
    if (confirmed) {
      navigate('/activities/call-leads', { state: { campaign } });
    }
  };

  const total_leads = Array.isArray(campaign?.activity_history) ? campaign?.activity_history.length : 0;
  const call_done = campaign?.activity_history.filter(v => v?.status == '1');
  const call_remain = campaign?.activity_history.filter(v => v?.status == '0');
  const call_followup = campaign?.activity_history.filter(v => v?.status == '2');
  const call_inprocess = campaign?.activity_history.filter(v => v?.status == '3');

  useEffect(() => {
  }, [campaign])
  const currentLoginUserJSON = getDecryptedUserData();
  let currentLoginUser = null;
  if (currentLoginUserJSON) {
    currentLoginUser = JSON.parse(currentLoginUserJSON)
  }
  console.log(currentLoginUser, 'currentLoginUser', currentLoginUserJSON)
  return (
    <>
      <Card title={campaign?.name} bordered={false} className="min-w-[450px]">
        <Row className="gap-5 mt-3 mb-3">
          <Col className="min-w-[150px]">
            <Typography.Text strong>Campaign Name:</Typography.Text>
          </Col>
          <Col>
            <Typography.Text>{campaign?.campaigns?.name}</Typography.Text>
          </Col>
        </Row>

        <Row className="gap-5 items-center justify-center">
          <Col className="min-w-[150px]">
            <Typography.Text strong>Agents</Typography.Text>
          </Col>
          <Col>
            <Avatar.Group>
              {campaign?.agents?.map((v, index) => (
                <Tooltip key={index} title={`${v?.name} (${v?.user_type})`}>
                  <Avatar icon={<UserOutlined />} />
                </Tooltip>
              ))}
            </Avatar.Group>
          </Col>
        </Row>

        {currentLoginUser?.role_name !== 'agent' && <Row className="gap-5 mt-3">
          {/* <Col className="min-w-[150px]">
            <Typography.Text strong>Call</Typography.Text>
          </Col> */}
          <Col>
            {/* <Progress percent={total_leads ? (call_done.length / total_leads * 100).toFixed(2) : 0} showInfo={true} />
            <Typography.Text>Leads: {call_done.length}/{total_leads}</Typography.Text> */}
            <ProgressBar campaign={campaign} />
          </Col>
        </Row>}

        {/* <Row className="gap-5 mt-3">
          <Col className="min-w-[150px]">
            <Typography.Text strong>Last Actioner:</Typography.Text>
          </Col>
          <Col>
            <Typography.Text>Admin</Typography.Text>
          </Col>
        </Row> */}

        {/* <Row className="gap-5 mt-3">
          <Col className="min-w-[150px]">
            <Typography.Text strong>Started On:</Typography.Text>
          </Col>
          <Col>
            <Typography.Text>
              <DateTimeFormatter date={campaign?.created_at} />
            </Typography.Text>
          </Col>
        </Row> */}

        {currentLoginUser?.role_name === 'agent' &&
          <Row className="gap-5 mt-4">
            {campaign?.status == '1' ? call_remain.length > 0 || call_followup.length > 0 ?

              <Button
                variant="outlined"
                className="w-full mt-5"
                disabled={total_leads !== 0 && call_done.length == total_leads}
                onClick={handleResumeClick}
              >
                Resume
              </Button>
              : call_inprocess.length > 0 ? <p>Few leads are still pending. Please reload in 30 minutes if not completed
                by another agent. </p> :
                <Button
                  variant="outlined"
                  className="w-full mt-5"
                  disabled={true}
                >
                  Completed
                </Button>

              : <Button
                variant="outlined"
                disabled={total_leads !== 0 && call_done.length == total_leads}
                className="w-full mt-5"
              >
                In Process
              </Button>}
          </Row>
        }
      </Card>

      <Modal
        title={
          <div className="flex items-center gap-2">
            <ExclamationCircleOutlined className="text-yellow-500" />
            <span style={{ marginLeft: "10px" }}>Are you sure?</span>
          </div>
        }
        open={showConfirmation}
        onOk={() => handleConfirmation(true)}
        onCancel={() => handleConfirmation(false)}
        okText="Yes"
        cancelText="No"
        width={400}
        centered
      >
        <p>Are you sure you want to proceed this Call Activity?</p>
      </Modal>
    </>
  );
};

export default CampaignCard;