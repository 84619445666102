import React, { useState, useEffect } from "react";
import { dispatch } from "store/index";
import {
    FetchAllEmailTemplates,
    FetchAllEmailSettings,
    FetchEditActivity,
    FetchActivityDetails,
    StartActivityProcess,
    FetchCallActivity,
} from "store/action/index";
import { useLocation, useNavigate } from "react-router-dom";
import MainCard from "components/MainCard";
import { Table, Button, Select, Typography, Form, Input, Checkbox } from "antd";
import { Grid, FormControlLabel } from "@mui/material";
import { connect } from "react-redux";
import AddLeadsDrawer from "./AddLeadDrawer";
import { IoMdAddCircle } from "react-icons/io";
import { toast } from 'react-toastify';
import { EditActivityLeadsLogs } from "components/columns/columns";
import TextRichEditor from "components/text-rich-editor/TextRichEditor";
import axios from 'axios';
import * as url from "../../store/constant/Endpoints";
const CryptoJS = require("crypto-js");

const { Option } = Select;
const { Title } = Typography;

const EmailActivityEdit = ({
    emailTemplates,
    emailSettings,
    activityCall,
    activityDetail,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [form] = Form.useForm();


    const initialData = location?.state?.data || {};
    const initialstaus = initialData?.status || 0;
    const [STATUS, setStatus] = useState(initialstaus);


    const [templateOptions, setTemplateOptions] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(initialData?.template_id || null);
    const [isProgrammaticUpdate, setIsProgrammaticUpdate] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState(initialData?.setting_id || null);
    const [leadsDataSource, setLeadsDataSource] = useState([]);
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [loadingBtnStart, setLoadingBtnStart] = useState(false)
    const [editorValue, setEditorValue] = useState("");
    const [isPasteHtml, setIsPasteHtml] = useState(false);
    const [hasFormBeenModified, setHasFormBeenModified] = useState(false);

    useEffect(() => {

        const fetchData = async () => {
            try {
                await Promise.all([
                    dispatch(FetchAllEmailTemplates()),
                    dispatch(FetchAllEmailSettings())
                ]);

                if (initialData?.activity_id_specific) {
                    const params = { id: initialData.activity_id_specific };
                    dispatch(FetchCallActivity({ params }));
                }
            } catch (error) {
                console.error('Error fetching initial data:', error);
                toast.error('Failed to load data. Please try again.');
            }
        };

        fetchData();
    }, [initialData?.activity_id_specific]);

    useEffect(() => {
        if (activityCall?.data?.data?.[0]?.leads) {
            const initialLeads = initialData?.leads || [];
            const activityLeads = activityCall.data?.data[0].leads || [];

            setLeadsDataSource([...initialLeads, ...activityLeads]);
            // console.log("activeCallCheckInitailData", activityCall?.data?.data[0]?.activity_history)
        }
    }, [activityCall, initialData?.leads]);

    useEffect(() => { console.log("STautusss", STATUS) }, [STATUS])

    const HandleSave = async (status_id) => {
        try {
            if (status_id === 1 && !selectedProvider) {
                toast.error("Provider must be selected");
                return;
            }

            let finalTemplateId = selectedTemplate;

            if (hasFormBeenModified) {
                try {
                    finalTemplateId = await createNewTemplate();
                    await Promise.all([
                        dispatch(FetchAllEmailTemplates()),
                        dispatch(FetchAllEmailSettings())
                    ]);
                } catch (error) {
                    console.error('Error creating template:', error);
                    toast.error("Failed to create template. Please try again.");
                    return;
                }
            }

            const payload = {
                activity_id: initialData?.activity_id_specific,
                campaign_id: initialData?.campaign_id,
                template_id: finalTemplateId,
                setting_id: selectedProvider,
                status: status_id,
            };
            setLoadingBtn(true)
            dispatch(FetchEditActivity({
                fieldData: payload,
                isNavigate: handleNavigate,
                setLoadingBtn: setLoadingBtn
            }));

        } catch (error) {
            console.error('Error saving:', error);
            toast.error("Please fill out all required fields");
        }
    };

    const ProccedHitApi = () => {
        setLoadingBtnStart(true)
        dispatch(StartActivityProcess({ activity_id: initialData?.records?.id, setLoadingBtnStart: setLoadingBtnStart, isNavigate: handleNavigate }));
    }

    const handleTemplateChange = (value) => {
        // console.log("SleectValueFUnctionInsisideData", value)
        setSelectedTemplate(value);
        if (!value) {
            form.resetFields();
            setEditorValue("");
            return;
        }

        const selectedTemplateData = templateOptions?.find(t => t.id === value);
        if (selectedTemplateData) {
            const formValues = {
                templateName: selectedTemplateData.name || '',
                subject: selectedTemplateData.subject || '',
                footer: selectedTemplateData.footer || '',
                body: selectedTemplateData.body || '',
            };

            setIsProgrammaticUpdate(true);
            form.setFieldsValue(formValues);
            setEditorValue(selectedTemplateData.body || '');

            setTimeout(() => setIsProgrammaticUpdate(false), 0);
        }
    };

    useEffect(() => {
        if (Array.isArray(emailTemplates)) {
            setTemplateOptions(emailTemplates);
        }
    }, [emailTemplates]);

    useEffect(() => {
        if (templateOptions?.length !== 0 && initialData?.template_id) {
            handleTemplateChange(initialData?.template_id)
        }
    }, [templateOptions, initialData?.template_id]);

    const createNewTemplate = async () => {
        try {
            const values = await form.validateFields();
            const payload = {
                name: values.templateName,
                subject: values.subject,
                body: isPasteHtml ? values.body : editorValue,
                footer: values.footer,
            };

            const token = localStorage.getItem("token");
            if (!token) {
                throw new Error("Authentication token not found");
            }

            const decryptedToken = CryptoJS.AES.decrypt(
                token,
                url.encryptionSecret
            ).toString(CryptoJS.enc.Utf8);

            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${decryptedToken}`,
                },
            };

            const response = await axios.post(
                `${url?.base_url}${url.email_templates}`,
                payload,
                config
            );

            if (!response?.data?.data?.id) {
                throw new Error("Invalid response from server");
            }

            return response.data.data.id;

        } catch (error) {
            console.error('Error creating template:', error);
            throw new Error(error.response?.data?.message || "Failed to create template");
        }
    };

    const handleNavigate = () => {
        navigate(-1);
    };

    const getActivityHistory = () => {
        if (activityCall?.data?.data && Array.isArray(activityCall?.data?.data) && activityCall?.data?.data?.length > 0) {
            return activityCall.data?.data[0]?.activity_history || [];
        }
        return [];
    };

    return (
        <MainCard>
            <div>
                <h3 style={{ marginBottom: "20px" }}>Edit Email Activity</h3>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ justifyContent: "left" }}>
                        <AddLeadsDrawer
                            leadsDataSource={leadsDataSource}
                            datacampaign={initialData}
                            handleNavigate={handleNavigate}
                            status={STATUS}
                        >
                            <Button
                                style={{
                                    backgroundColor: "#0055A2",
                                    color: "#fff",
                                    fontFamily: "poppins",
                                    fontSize: "13px",
                                }}
                                size="large"
                            >
                                <IoMdAddCircle color="#fff" size={24} />
                                Add Leads
                            </Button>
                        </AddLeadsDrawer>
                    </div>

                    <div style={{ display: "flex", gap: 10 }}>
                        <div className="count-box">
                            <span>Total: </span>
                            {activityCall?.data?.data?.[0]?.activity_history?.length || 0}
                        </div>
                        <div className="count-box">
                            <span>New: </span>
                            {activityCall?.data?.data?.[0]?.leads?.length || 0}
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: 30, display: "flex", gap: 20 }}>
                    <div style={{ marginBottom: 10 }}>
                        <label style={{ marginBottom: 5, fontSize: 13 }}>Select Template</label>
                        <Select
                            placeholder="Select Template"
                            style={{ width: 300 }}
                            value={selectedTemplate}
                            disabled={STATUS === 1 || STATUS === 3 || STATUS === 4}
                            onChange={handleTemplateChange}
                            allowClear
                        >
                            {templateOptions?.map((template) => (
                                <Option key={template.id} value={template.id}>
                                    {template.name}
                                </Option>
                            ))}
                        </Select>
                    </div>

                    <div style={{ marginBottom: 10 }}>
                        <label style={{ marginBottom: 5, fontSize: 13 }}>Select Provider</label>
                        <Select
                            placeholder="Provider"
                            style={{ width: 300 }}
                            value={selectedProvider}
                            disabled={STATUS === 1 || STATUS === 3 || STATUS === 4}
                            onChange={(v) => setSelectedProvider(v)}
                        >
                            {emailSettings?.map((setting) => (
                                <Option key={setting.id} value={setting.id}>
                                    {setting.hostname}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>

                <MainCard style={{ marginTop: 20 }}>
                    <Form
                        form={form}
                        layout="vertical"
                        onValuesChange={(changedValues, allValues) => {
                            if (!isProgrammaticUpdate && (
                                changedValues.templateName ||
                                changedValues.subject ||
                                changedValues.body ||
                                changedValues.footer
                            )) {
                                setHasFormBeenModified(true);
                            }
                        }}
                    >
                        <Form.Item
                            label="Template Name"
                            name="templateName"
                            rules={[{ required: true, message: "Template Name is required" }]}
                        >
                            <Input placeholder="Enter template name" disabled={STATUS === 1 || STATUS === 3 || STATUS === 4} />
                        </Form.Item>

                        <Form.Item
                            label="Subject"
                            name="subject"
                            rules={[{ required: true, message: "Subject is required" }]}
                        >
                            <Input placeholder="Enter subject" disabled={STATUS === 1 || STATUS === 3 || STATUS === 4} />
                        </Form.Item>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isPasteHtml}
                                    onChange={(e) => setIsPasteHtml(e.target.checked)}
                                    disabled={STATUS === 1 || STATUS === 3 || STATUS === 4}
                                    color="primary"
                                />
                            }
                            label="Edit as HTML"
                        />

                        <Form.Item
                            label="Body"
                            name="body"
                            rules={[{ required: true, message: "Body is required" }]}
                        >
                            {isPasteHtml ? (
                                <Input.TextArea
                                    rows={7}
                                    placeholder="<>...</>"
                                    disabled={STATUS === 1 || STATUS === 3 || STATUS === 4}
                                />
                            ) : STATUS === 1 || STATUS === 3 || STATUS === 4 ? (
                                <div dangerouslySetInnerHTML={{ __html: editorValue }}></div>
                            ) : (
                                <TextRichEditor
                                    value={editorValue}
                                    onChange={setEditorValue}
                                    placeholder="Enter email body"
                                    disabled={STATUS === 1 || STATUS === 3 || STATUS === 4}
                                />
                            )}
                        </Form.Item>

                        <Form.Item label="Footer" name="footer">
                            <Input.TextArea
                                rows={2}
                                placeholder="Enter email footer body"
                                disabled={STATUS === 1 || STATUS === 3 || STATUS === 4}
                            />
                        </Form.Item>
                    </Form>
                </MainCard>
                <div style={{ display: "flex", justifyContent: "end", gap: 30, marginTop: 20 }}>

                    {(STATUS == 0 || STATUS == 2) && (
                        <><Button
                            style={{
                                backgroundColor: "#0055A2",
                                color: "#fff",
                                fontFamily: "poppins",
                                fontSize: "13px",
                                minWidth: "150px",
                            }}
                            onClick={() => HandleSave(1)}
                            size="large"
                            loading={loadingBtn}
                        >
                            Save
                        </Button>
                            <Button
                                style={{
                                    backgroundColor: "#0055A2",
                                    color: "#fff",
                                    fontFamily: "poppins",
                                    fontSize: "13px",
                                    minWidth: "150px",
                                }}
                                onClick={() => HandleSave(2)}
                                size="large"
                                loading={loadingBtn}
                            >
                                Save as Draft
                            </Button>
                        </>
                    )}
                    {[1, 3, 4].includes(STATUS) && (
                        <Button
                            style={{
                                backgroundColor: "#0055A2",
                                color: "#fff",
                                fontFamily: "poppins",
                                fontSize: "16px",
                                padding: "5px 20px",
                            }}
                            size="large"
                            onClick={ProccedHitApi}
                            disabled={STATUS === 4}
                            loading={loadingBtnStart}
                        >
                            {STATUS === 3 ? "Processing" : "Start"}
                        </Button>
                    )}
                </div>

                <div style={{ overflowX: "auto" }}>

                    <Table
                        className="border rounded"
                        style={{ marginTop: 25 }}
                        dataSource={getActivityHistory()}
                        columns={EditActivityLeadsLogs()}
                    />
                </div>

            </div>
        </MainCard>
    );
};

const mapStateToProps = (state) => ({
    emailTemplates: state?.EmailTemplatesReducer?.emailTemplates || [],
    emailSettings: state?.AllEmailSettingReducer?.allEmailsetting?.data || [],
    activityDetail: state?.ActivityDetailRedc?.activityDetails,
    activityCall: state?.ActivityCallRedc?.activityCall
});

export default connect(mapStateToProps)(EmailActivityEdit);