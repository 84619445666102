import { OFFLINE_CALLS_GET } from "../constant/ActionType";

export default function ALLViewCallOffline(
    state = {
        allofflinecall: [],
        statusCode: null,
    },
    action
) {
    switch (action.type) {
        case OFFLINE_CALLS_GET:
            return {
                ...state,
                allofflinecall: action.data,
                statusCode: action.status,
            };

        default:
    }

    return state;
}
