import React, { useState, useEffect } from "react";
import { dispatch } from "store/index";
import {
    FetchAllWhatsappTemplates,
    FetchCreateWhatsAppSetting,
    FetchEditActivity,
    FetchCallActivity,
    StartActivityProcess,
    FetchActivityDetails,
    FetchWhatsappTemplateSubIDAgains,
} from "store/action/index";
import { useLocation, useNavigate } from "react-router-dom";
import MainCard from "components/MainCard";
import { Table, Button, Select, Typography, Tag } from "antd";
import { connect, useSelector } from "react-redux";
import AddLeadsDrawer from "./AddLeadDrawer";
import { IoMdAddCircle } from "react-icons/io";
import { toast } from 'react-toastify';
import { EditActivityLeadsLogs } from "components/columns/columns";
import EditActivityWhatsappTemplate from "./editactivitywhatsapptemplate";

const { Option } = Select;
const { Title } = Typography;

const WhatsAppActivityEdit = ({
    whatsappTemplates,
    whatsappSettings,
    activityDetail,
    activityCall,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const initialData = location?.state?.data || {};
    const initialstaus = initialData?.status || 0;
    const [STATUS, setStatus] = useState(initialstaus);
    const [selectedTemplate, setSelectedTemplate] = useState(initialData?.template_id || null);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [editActivityTemplate, setEditActivityTemplate] = useState(null);
    const [leadsDataSource, setLeadsDataSource] = useState([]);
    const [isProgrammaticUpdate, setIsProgrammaticUpdate] = useState(false);
    const [hasFormBeenModified, setHasFormBeenModified] = useState(false);
    const [getSettinIDGet, setGetSeetingIGEre] = useState(null);
    const [MainbuttonCLicked, setMainButtonClicked] = useState(false);
    const [newtemplateCreateID, setNewTemplateCreatedID] = useState(null);
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [loadingBtnStart, setLoadingBtnStart] = useState(false)
    const [isLoading, setIsLoading] = useState('')
    const selector = useSelector((state) => state);
    const whatsappSettingData = selector?.AllWhatsappSettingReducer?.allWhatsappsetting?.data ?? [];


    const parseTemplateData = (data) => {
        try {
            if (!data?.body) {
                return null;
            }
            return JSON.parse(data.body);
        } catch (error) {
            console.error("Error parsing template data:", error);
            return null;
        }
    };

    useEffect(() => {
    }, [editActivityTemplate])




    const AllchannelData = selector?.AllWhatsappChannnel?.allwhatsappchannel;
    const activeChannels = Array.isArray(AllchannelData)
        ? AllchannelData.filter(channel => channel.active === 1)
        : [];


    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([
                    dispatch(FetchAllWhatsappTemplates()),
                    dispatch(FetchCreateWhatsAppSetting({ setLoading: () => { } }))
                ]);

                if (initialData?.activity_id_specific) {
                    const params = { id: initialData.activity_id_specific };
                    dispatch(FetchCallActivity({ params }));
                }
            } catch (error) {
                console.error('Error fetching initial data:', error);
                toast.error('Failed to load data. Please try again.');
            }
        };

        fetchData();
        console.log("inistaDtaatatat", initialData?.records)
    }, [initialData?.activity_id_specific]);

    useEffect(() => {
        dispatch(FetchCreateWhatsAppSetting({ setLoading: setIsLoading }));
    }, []);

    useEffect(() => {
        if (activityCall?.data?.data?.[0]?.leads) {
            const initialLeads = initialData?.leads || [];
            const activityLeads = activityCall?.data?.data[0].leads || [];
            setSelectedProvider({ subjectId: activityCall?.data?.data[0]?.subject_id || null })
            setLeadsDataSource([...initialLeads, ...activityLeads]);
        }
    }, [activityCall, initialData]);

    const handleTemplateChange = (value) => {
        setSelectedTemplate(value);
        const selectedTemplateData = whatsappTemplates?.find(template => template?.id === value);
        const mainSelectedTemplateData = parseTemplateData(selectedTemplateData);

        if (selectedProvider && mainSelectedTemplateData) {
            const transformedTemplate = {
                name: mainSelectedTemplateData?.name,
                channelType: 'WHATSAPP',
                body: mainSelectedTemplateData?.content?.text || '',
                footer: mainSelectedTemplateData.content?.footer?.text || '',
                header: {
                    content: mainSelectedTemplateData.content?.header?.text || ''
                },
                subject: selectedProvider.subject || '',
                subjectId: selectedProvider.subjectId || '',
                Template_Status: mainSelectedTemplateData.status || '',
                content: {
                    keyboard: {
                        rows: mainSelectedTemplateData.content?.keyboard?.rows || []
                    }
                }
            };

            setIsProgrammaticUpdate(true);
            setEditActivityTemplate(transformedTemplate);
            setTimeout(() => setIsProgrammaticUpdate(false), 100);
        }
    };

    const handleNavigate = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (whatsappTemplates?.length !== 0 && initialData?.template_id) {
            handleTemplateChange(initialData?.template_id)
        }
    }, [whatsappTemplates, initialData?.template_id])

    const HandleSave = async (status_id) => {
        setMainButtonClicked(true);
        if (status_id === 1) {
            if (!selectedTemplate) {
                toast.error("Template must be selected");
                return;
            }
            if (!selectedProvider) {
                toast.error("Provider must be selected");
                return;
            }
        }

        // Wait for template creation before proceeding
        if (hasFormBeenModified) {
            // Navigation will be handled by the child component
            return;
        }
        // If no modifications, proceed with normal save
        const payload = {
            activity_id: initialData?.activity_id_specific,
            campaign_id: initialData?.campaign_id,
            template_id: newtemplateCreateID || selectedTemplate,
            // setting_id: selectedProvider?.id,
            setting_id: getSettinIDGet,
            subject_id: selectedProvider?.subjectId,
            status: status_id,
        };
        // if (editActivityTemplate?.Template_Status == "PENDING") {
        setStatus(1)
        setLoadingBtn(true)
        dispatch(FetchEditActivity({ fieldData: payload, setLoadingBtn: setLoadingBtn }));
        // console.log("CHeckinssgPyaload", selectedProvider)

    };

    const ProccedHitApi = () => {
        setLoadingBtnStart(true)
        dispatch(StartActivityProcess({ activity_id: initialData?.records?.id, setLoadingBtnStart: setLoadingBtnStart, isNavigate: handleNavigate }));
    }

    useEffect(() => {
        if (selectedProvider) {
            // console.log("HitSubagainWhatsappTemplateAPiSubjectId", selectedProvider)
            dispatch(FetchWhatsappTemplateSubIDAgains({ subjecdId: selectedProvider?.subjectId, setLoading: () => { } }));
        }
    }, [selectedProvider])

    const handleTemplateCreated = (templateId) => {
        setNewTemplateCreatedID(templateId)
        if (selectedProvider) {
            dispatch(FetchWhatsappTemplateSubIDAgains({ subjecdId: selectedProvider?.subjectId, setLoading: () => { } }));
        }
        // console.log('Template created with ID:', templateId);
    };

    const getActivityHistory = () => {
        if (activityCall?.data?.data && Array.isArray(activityCall?.data?.data) && activityCall?.data?.data?.length > 0) {
            return activityCall.data?.data[0]?.activity_history || [];
        }
        return [];
    };
    useEffect(() => {
    }, [activityCall])


    return (
        <MainCard>
            <div>
                <h3 style={{ marginBottom: "20px" }}>Edit WhatsApp Activity</h3>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ justifyContent: "left" }}>
                        <AddLeadsDrawer
                            leadsDataSource={leadsDataSource}
                            datacampaign={initialData}
                            handleNavigate={handleNavigate}
                            status={STATUS}                        >
                            <Button
                                style={{
                                    backgroundColor: "#0055A2",
                                    color: "#fff",
                                    fontFamily: "poppins",
                                    fontSize: "13px",
                                }}
                                size="large"
                            >
                                <IoMdAddCircle color="#fff" size={24} />
                                Add Leads
                            </Button>
                        </AddLeadsDrawer>
                    </div>


                    <div style={{ display: "flex", gap: 10 }}>
                        <div className="count-box">
                            <span>Total: </span>
                            {activityCall?.data?.data?.[0]?.activity_history?.length || 0}

                        </div>
                        <div className="count-box">
                            <span>New: </span>
                            {/* {activityCall?.data?.[0]?.leads && Array.isArray(activityCall?.data?.[0]?.leads) ?
                                activityCall.data[0].leads.length : 0} */}
                            {activityCall?.data?.data?.[0]?.leads?.length || 0}

                        </div>

                    </div>
                </div>

                <div style={{ marginTop: 30, display: "flex", gap: 20 }}>
                    <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column' }}>
                        <label style={{ marginBottom: 5, fontSize: 13 }}>Select Channels</label>
                        <Select
                            placeholder="Provider"
                            style={{ width: 300 }}
                            value={selectedProvider ? selectedProvider.subjectId : undefined} // Display subjectId only
                            disabled={STATUS === 1 || STATUS === 3 || STATUS === 4}
                            onChange={(subjectId) => {
                                const selected = activeChannels.find((setting) => setting.subjectId === subjectId);
                                setSelectedProvider(selected);
                            }}
                        >
                            {Array.isArray(activeChannels) && activeChannels.length !== 0 && activeChannels.map((setting) => (

                                <Option key={setting.id} value={setting.subjectId}> {/* Pass subjectId as the value */}
                                    {setting.name}
                                </Option>
                            ))}
                        </Select>
                    </div>

                    <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column' }}>
                        <label style={{ marginBottom: 5, fontSize: 13 }}>Select Template</label>
                        <Select
                            placeholder="Select Template"
                            style={{ width: 300 }}
                            value={selectedTemplate}
                            disabled={STATUS === 1 || STATUS === 3 || STATUS === 4}
                            onChange={handleTemplateChange}
                        >
                            {Array.isArray(whatsappTemplates) && whatsappTemplates.length > 0 && whatsappTemplates?.map((e) => parseTemplateData(e))
                                .filter((template) => template.status === "APPROVED")
                                .map((template) => (
                                    <Option key={template?.id} value={template?.id}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                            <span>{template.name}</span>
                                            <Tag
                                                style={{ fontSize: 9, padding: '2px 6px', lineHeight: '16px' }}
                                                color="green"
                                            >
                                                {template.status}
                                            </Tag>
                                        </div>
                                    </Option>
                                ))
                            }


                        </Select>
                    </div>

                </div>

                <div style={{ marginTop: 20 }}>
                    <EditActivityWhatsappTemplate
                        isDisabled={STATUS === 1 || STATUS === 3 || STATUS === 4}
                        EditActivityTemplate={editActivityTemplate}
                        setHasFormBeenModified={setHasFormBeenModified}
                        isProgrammaticUpdate={isProgrammaticUpdate}
                        setGetSeetingIGEre={setGetSeetingIGEre}
                        setIsProgrammaticUpdate={setIsProgrammaticUpdate}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        hasFormBeenModified={hasFormBeenModified}
                        MainbuttonCLicked={MainbuttonCLicked}
                        onTemplateCreated={handleTemplateCreated}

                    />
                </div>
                <div style={{ display: "flex", justifyContent: "end", gap: 30, marginTop: 20 }}>

                    {(STATUS == 0 || STATUS == 2) && (
                        <>
                            <Button
                                style={{
                                    backgroundColor: "#0055A2",
                                    color: "#fff",
                                    fontFamily: "poppins",
                                    fontSize: "13px",
                                    minWidth: "150px",
                                }}
                                onClick={() => HandleSave(1)}
                                size="large"
                                loading={loadingBtn}
                            >
                                Save
                            </Button>
                            <Button
                                style={{
                                    backgroundColor: "#0055A2",
                                    color: "#fff",
                                    fontFamily: "poppins",
                                    fontSize: "13px",
                                    minWidth: "150px",
                                }}
                                onClick={() => HandleSave(2)}
                                size="large"
                                loading={loadingBtn}
                            >
                                Save as Draft
                            </Button>
                        </>
                    )}

                    {STATUS == 1 ? <Button
                        style={{
                            backgroundColor: "#0055A2",
                            color: "#fff",
                            fontFamily: "poppins",
                            fontSize: "13px",
                        }}
                        size="large"
                        loading={loadingBtnStart}
                        onClick={ProccedHitApi}
                    >
                        Start
                    </Button> : <></>}
                </div>

                <div style={{ overflowX: "auto" }}>
                    <Table
                        className="border rounded"
                        style={{ marginTop: 25 }}
                        dataSource={getActivityHistory()}
                        columns={EditActivityLeadsLogs()}
                    />
                </div>

            </div>
        </MainCard>
    );
};

const mapStateToProps = (state) => ({
    whatsappTemplates: state?.WhatsappTemplatesReducer?.whatsappTemplates,
    whatsappSettings: state?.AllWhatsappSettingReducer?.allWhatsappsetting?.data,
    activityDetail: state?.ActivityDetailRedc?.activityDetails,
    activityCall: state?.ActivityCallRedc?.activityCall,
});

export default connect(mapStateToProps)(WhatsAppActivityEdit);