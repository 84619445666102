import React, { useState, useEffect } from "react";
import {
  Button,
  Drawer,
  Space,
  Form,
  Input,
  Steps,
  Select,
  DatePicker,
} from "antd";
import moment from "moment"; // Import moment for date manipulation
import CsvUploaderLeads from "components/inputs/CsvUploaderLeads";
import { useSelector } from "react-redux";
import PaginatedSelect from "components/selects/SelectPagination";
import { dispatch } from "store/index";
import {
  CreateCampaign,
  FetchAllSelectLeads,
  FetchGetAllTags,
} from "store/action/index";
import en from "antd/es/date-picker/locale/en_US";

const { Option } = Select;

const DrawerRight = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoasding, setIssLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectTags, setSelectTags] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false)

  const selector = useSelector((state) => state);
  const allMembersData = selector?.GetAllUsers?.getAllUsers?.users || [];
  const FilterMember = Array.isArray(allMembersData)
    ? allMembersData?.filter((e) => e?.user_type == "member")
    : [];
  const allLeadsData = selector?.AllLeadsSelectReducer?.allLeads?.data ?? [];
  const allTagsData = selector?.AllGetTagsReducer?.alltags?.data ?? [];
  // Enhanced locale configuration
  const customLocale = {
    ...en,
    lang: {
      ...en.lang,
      fieldDateFormat: "YYYY-MM-DD",
      fieldDateTimeFormat: "YYYY-MM-DD HH:mm:ss",
      yearFormat: "YYYY",
      cellYearFormat: "YYYY",
    },
  };

  const resetForm = () => {
    form.resetFields();
    setDataSource([]);
    setCurrentStep(0);
    setSelectTags([]);
    form.setFieldsValue({
      activities: [{}],
    });
  };

  useEffect(() => {
    let params = { per_page: "all" };
    dispatch(FetchGetAllTags({ params: params, setLoading: setIssLoading }));
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      activities: [{}],
    });
  }, [form]);

  const showLargeDrawer = () => {
    setSize("large");
    setOpen(true);
  };

  const onClose = () => {
    resetForm();
    setOpen(false);
  };

  const onFinish = (values) => {
    const allValues = {
      ...form.getFieldsValue(true),
      "csv-leads": dataSource,
    };

    // Ensure start_date is provided
    if (!allValues.start_date) {
      alert("Please enter a valid start date.");
      return;
    }

    // Helper function to format date and time
    const formatDateTime = (dateField) => {
      const dateValue = allValues[dateField];
      if (!dateValue) return null;
      const formattedDate = moment(dateValue);
      return formattedDate.isValid()
        ? `${formattedDate.format("YYYY-MM-DD")} ${dateValue.$H || "00"}:${dateValue.$m || "00"}:${dateValue.$s || "00"}`
        : null;
    };

    // Format the start_date
    allValues.start_date = formatDateTime("start_date");

    // Format the end_date only if it exists
    allValues.end_date = allValues.end_date ? formatDateTime("end_date") : null;
    setLoadingBtn(true)
    // Dispatch the API call
    dispatch(
      CreateCampaign({
        setLoading: setIsLoading,
        setLoadingBtn: setLoadingBtn,
        fieldData: allValues,
        setOpen: setOpen,
        onClose: () => {
          resetForm();
          onClose();
        },
      })
    );
  };



  const next = async () => {
    try {
      // Validate current step's fields
      await form.validateFields();
      setCurrentStep((prev) => prev + 1);
    } catch (errorInfo) {
      console.error("Validation Failed:", errorInfo);
    }
  };

  const prev = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const callAllLeadsApi = (setLoading, params) => {
    dispatch(FetchAllSelectLeads({ setLoading: setLoading, params: params }));
  };

  const handleTagsChange = (value) => {
    setSelectTags(value);
  };

  const steps = [
    {
      title: "Basic Settings",
      description: "Campaign Settings",
      fields: (
        <>
          <div style={{ display: "flex", gap: 10, justifyContent: "space-between" }}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please input your Name!" }]}
              style={{ width: "50%" }}
            >
              <Input className="p-2" />
            </Form.Item>
            <Form.Item
              label="Members"
              name="members"
              rules={[{ required: true, message: "Please select a value" }]}
              style={{ width: "50%" }}
            >
              <Select placeholder="Select Members" mode="multiple">
                {FilterMember?.map((v) => (
                  <Select.Option key={v.id} value={v.id}>
                    {v.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="d-flex gap-2">
            <Form.Item
              style={{ width: "100%" }}
              label="Start Date"
              name="start_date"
              rules={[
                {
                  required: true,
                  message: "Please select a start date!",
                },
              ]}
            >
              <DatePicker
                showTime={{
                  format: "HH:mm:ss",
                  defaultValue: moment().startOf('day').set({ hour: 0, minute: 0, second: 0 }),
                }}
                format="YYYY-MM-DD HH:mm:ss"
                locale={customLocale}
                className="flex-1 p-2"
                style={{ width: "100%" }}
              />
            </Form.Item>

            <Form.Item
              style={{ width: "100%" }}
              label="End Date"
              name="end_date"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("start_date") <= value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("End date must be after start date!")
                    );
                  },
                }),
              ]}
            >
              <DatePicker
                showTime={{
                  format: "HH:mm:ss",
                  defaultValue: moment().startOf('day').set({ hour: 0, minute: 0, second: 0 }),
                }}
                format="YYYY-MM-DD HH:mm:ss"
                locale={customLocale}
                className="p-2"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </div>
          <Form.Item label="Description" name="description">
            <Input.TextArea rows={4} placeholder="Enter email description" />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Import Data",
      description: "Campaign Leads/Data",
      fields: (
        <>
          <div className="py-2">
            <Form.Item
              label="Leads"
              name="leads"
              style={{ width: "100%", marginBottom: "0px" }}
            >
              <PaginatedSelect
                optKey={"name"}
                optValue={"id"}
                opt={allLeadsData}
                callApi={callAllLeadsApi}
                allData={selector?.AllLeadsSelectReducer?.allLeads ?? {}}
              />
            </Form.Item>
          </div>
          <CsvUploaderLeads
            setDataSource={setDataSource}
            dataSource={dataSource}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <div style={{ width: "100%" }} onClick={showLargeDrawer}>
        {children}
      </div>
      <Drawer
        title="Add Campaign"
        zIndex={10000}
        width={1200}
        placement="right"
        size={size}
        onClose={onClose}
        open={open}
      >
        <div className="container-fluid mt-1">
          <div className="row">
            <Steps
              current={currentStep}
              items={steps.map((step) => ({
                title: step.title,
                description: step.description,
              }))}
              style={{ marginRight: "20px" }}
            />

            <Form
              form={form}
              name="basic"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ maxWidth: "100%" }}
              onFinish={onFinish}
              autoComplete="off"
              className="mt-3"
            >
              {steps[currentStep].fields}

              <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                <Space>
                  {currentStep > 0 && <Button onClick={prev}>Previous</Button>}

                  {currentStep < steps.length - 1 && (
                    <Button
                      type="primary"
                      onClick={next}
                      style={{ marginTop: "10px" }}
                    >
                      Next
                    </Button>
                  )}
                  {currentStep === steps.length - 1 && (
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loadingBtn}
                    >
                      Submit
                    </Button>
                  )}
                </Space>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default DrawerRight;