import React, { useEffect, useState } from "react";
import {
    Button, Row, Col, Spin, Select,
    DatePicker,
    TimePicker,
} from "antd";
import MainCard from "components/MainCard";
import moment from "moment"; // Import moment for date manipulation
import { Form, Input, message } from "antd";
import { CreateConstantOfflineCall, UpdateConstantOfflineCall } from "store/action/index";
import { dispatch } from "store/index";
import { useNavigate, useLocation } from "react-router-dom";
import en from "antd/es/date-picker/locale/en_US";

const AddOfflineCalls = () => {
    const { Option } = Select;
    const [form] = Form.useForm();
    const location = useLocation();
    const [loadinBtn, setLoadingBtn] = useState(false)

    const isEditing = location.state ? true : false;
    const initialData = location.state ? location.state : null;

    useEffect(() => {
        if (isEditing && initialData) {
            console.log("CHeckData", initialData)
            form.setFieldsValue({
                name: initialData.name,
                phone_number: initialData.phone_number,
                status: initialData.status,
                date_time: initialData.date_time ? moment(initialData.date_time, "YYYY-MM-DD HH:mm:ss") : null,
                duration: initialData.duration ? moment(initialData.duration, "HH:mm:ss") : null,
                question: initialData.question,
            });
        }
    }, [form, initialData, isEditing]);

    const onFinish = (values) => {
        setLoadingBtn(true);

        const formattedDateTime = values.date_time
            ? moment(values.date_time).format("YYYY-MM-DD HH:mm:ss")
            : null;

        const payload = {
            ...(isEditing && { id: initialData?.id, _method: "PUT" }),
            name: values.name,
            phone_number: values.phone_number,
            status: values.status,
            date_time: formattedDateTime, // Ensure formatted date_time
            duration: values.duration ? moment(values.duration).format("HH:mm:ss") : null,
            question: values.question,
        };

        if (isEditing) {
            message.success("Offline Call Updated Successfully");
            dispatch(UpdateConstantOfflineCall({ fieldData: payload, setLoadingBtn: setLoadingBtn }));
        } else {
            message.success("Offline Call Created Successfully!");
            console.log("fieldData", payload);
            dispatch(CreateConstantOfflineCall({ fieldData: payload, setLoadingBtn: setLoadingBtn }));
        }
    };

    const customLocale = {
        ...en,
        lang: {
            ...en.lang,
            fieldDateFormat: "YYYY-MM-DD",
            fieldDateTimeFormat: "YYYY-MM-DD HH:mm:ss",
            yearFormat: "YYYY",
            cellYearFormat: "YYYY",
        },
    };

    return (
        <>

            <MainCard>
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="name"
                                label="Name"
                                rules={[
                                    { required: false, message: "Please enter  Name" },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="phone_number"
                                label="Phone number"
                                rules={[
                                    { required: true, message: "Please enter phone number" },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>

                            <Form.Item
                                name="status"
                                label="Status"
                                tooltip="Select the active status"
                                rules={[{ required: true, message: "Please select the status" }]}
                            >
                                <Select>
                                    <Option value="Answer">Answer</Option>
                                    <Option value="No Answer">No Answer</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                style={{ width: "100%" }}
                                label="Start Date"
                                name="date_time"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select a start date!",
                                    },
                                ]}
                            >
                                <DatePicker
                                    showTime={{
                                        format: "HH:mm:ss",
                                        defaultValue: moment().startOf('day').set({ hour: 0, minute: 0, second: 0 }),
                                    }}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    locale={customLocale}
                                    className="flex-1 p-2"
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                style={{ width: "100%" }}
                                label="Duration"
                                name="duration"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select a duration!",
                                    },
                                ]}
                            >
                                <TimePicker
                                    format="HH:mm:ss"
                                    locale={customLocale}
                                    className="flex-1 p-2"
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>

                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="question"
                                label="Question"
                                rules={[
                                    { required: true, message: "Enter Yes/No" },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loadinBtn}>
                            {isEditing ? "Update" : "Submit"}
                        </Button>
                    </Form.Item>
                </Form>
            </MainCard>
        </>
    )
}

export default AddOfflineCalls