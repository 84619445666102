import React, { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import { Table, Spin, Pagination, Button, Modal } from "antd";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { IoMdAddCircle } from "react-icons/io";
import { dispatch } from "store/index";
import { allOfflineCallsColumns } from "components/columns/columns";
import { EditOutlined } from "@ant-design/icons";
import { FetchAllLeads, FetchOfflineCallsData, CreateBulkUploader } from "store/action/index";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import moment from "moment";
import { onPageChange } from "helper/paginationHelper";
import { useNavigate } from "react-router-dom";
import {
  can_create_offline_calls,
  can_view_offline_calls,
  can_bulk_offline_calls,
  can_update_offline_calls
} from "helper/permissionsCheck";
import CsvUploadByofllineCall from '../../components/inputs/csvUploadOfflineCall'

const OflineCalls = ({ allofflinecall }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [params, setparams] = useState({
    page: 1,
  });

  const columns = allOfflineCallsColumns({ params, setparams });

  columns.push(true ? {
    title: "Actions",
    key: "actions",
    render: (text, record) => {
      return (
        <span>
          {can_update_offline_calls ? (<IconButton
            className="mx-2"
            type="primary"
            onClick={() => {
              navigate("/offline/add-offline", { state: record });
            }}
          >
            <EditOutlined />
          </IconButton>) : <></>}
        </span>
      );
    },
  } : <></>);

  useEffect(() => {
    setLoading(true)
    dispatch(FetchOfflineCallsData({ params: params, setLoading: setLoading }));
  }, [params])

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setLoading(true);
    const transformedDataSource = dataSource.map(item => ({
      ...item,
      date_time: moment(item.date_time, 'M/D/YYYY H:mm').format('YYYY-MM-DD HH:mm:ss')
    }));
    let fieldDataFormat = { data: transformedDataSource };
    dispatch(CreateBulkUploader({ fieldData: fieldDataFormat, setLoading: setLoading }));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>

      <Modal
        title="Basic Modal"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <CsvUploadByofllineCall
          setDataSource={setDataSource}
        />

      </Modal>
      <div className="mb-4">
        <MainCard>
          <div style={{ display: "flex" }}>
            <Grid container alignItems="center" justifyContent="flex-start">
              <Grid item>
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: "600",
                  }}
                >
                  All Offline Calls
                </span>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="flex-end">
              {can_update_offline_calls ? (<Grid item>
                <Button
                  disableElevation
                  className="px-2"
                  size="large"
                  type="submit"
                  onClick={showModal}
                >
                  <span c lassName="mx-2">
                    <IoMdAddCircle color="#0055A2" size={26} />
                  </span>
                  Upload CSV
                </Button>
              </Grid>) : <></>}
              {can_create_offline_calls ? (<Grid item>
                <Link to={"/offline/add-offline"}>
                  <Button
                    disableElevation
                    // className="px-2"
                    size="large"
                    type="submit"
                  >
                    <span className="mx-2">
                      <IoMdAddCircle color="#0055A2" size={26} />
                    </span>
                    Add Offline Call
                  </Button>
                </Link>
              </Grid>) : <></>}
            </Grid>
          </div>
        </MainCard>
      </div>
      <MainCard>

        <Table
          dataSource={allofflinecall?.data?.data ?? []}
          columns={columns}
          pagination={false}
          loading={loading}
        />
        <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>

          <Pagination
            total={allofflinecall?.data?.total} // Use actual total count
            current={params?.page}
            pageSize={allofflinecall?.data?.per_page || 10}
            showSizeChanger={false}
            onChange={(page) => onPageChange(setparams, params, page)}
          />
        </div>
      </MainCard>
    </>
  )
}


const mapStateToProps = (state) => {
  return {
    allofflinecall: state.ALLViewCallOffline.allofflinecall,
  };
};

export default connect(mapStateToProps)(OflineCalls);