import { MdHome } from "react-icons/md"; // Main Navigation Icon
import { HiOutlineUserGroup, HiOutlineUserAdd } from "react-icons/hi"; // Lead Icons
import { IoCallSharp } from "react-icons/io5";
import {
  can_create_offline_calls,
  can_view_offline_calls,
  can_bulk_offline_calls,
  can_update_offline_calls,
} from "helper/permissionsCheck";
const icons = {
  navigation: MdHome, // General Navigation icon
  allLeads: IoCallSharp, // Icon for All Leads
  addLead: HiOutlineUserAdd, // Icon for Adding a Lead
};

const offlineCalls =
  can_create_offline_calls || can_view_offline_calls || can_bulk_offline_calls || can_update_offline_calls
    ? {
      id: "call-offline",
      title: "Offline Calls",
      icon: icons.allLeads,
      type: "group",
      children: [
        (can_view_offline_calls) && {
          id: "all-leads",
          title: "Offline Calls",
          type: "item",
          url: "/offline/offline-calls",
          icon: icons.allLeads,
          breadcrumbs: true,
        },
      ].filter(Boolean),
    }
    : null;

export default offlineCalls;
