import { useEffect, useState } from "react";
import { RegisterStatus, CONNECT_STATUS, useSIPProvider } from "react-sipjs";
import { CallSessionItem } from "./CallSessionItem";
import { dispatch } from "store/index";
import { AddCallLog } from "store/action/index";

export const CallSip = ({ username, password, callTo, currentLead, allData, setLeadFieldDisabledCond }) => {
    const {
        connectAndRegister,
        sessionManager,
        sessions,
        registerStatus,
        connectStatus,
    } = useSIPProvider();

    useEffect(() => {
        connectAndRegister({
            username: username,
            password: password,
        });
    }, [username, password])


    useEffect(() => {
        console.log(sessionManager, sessionManager?._state, sessionManager?.Te?._state, sessions)

    }, [sessionManager, sessions])


    const formatDate = (date) => {
        if (date) {
            return date?.toISOString().slice(0, 19).replace('T', ' ');
        }
    };

    const handleStartCall = (status, duration, answerAt, download_path) => {
        const validTimestamp = answerAt && !isNaN(new Date(answerAt).getTime());

        const payload = {
            "lead_id": currentLead?.lead?.id,
            "call_started_on": validTimestamp
                ? formatDate(new Date(answerAt))
                : formatDate(new Date()),
            "call_ended_on": status == 'Terminated' ? formatDate(new Date()) : null,
            "call_status": status == 'Established' ? 'Established' : status == 'Terminated' ? 'Terminated' : "Calling",
            "call_duration": duration ? duration : null,
            "activity_history_id": currentLead?.id,
            "current_activity_id": allData?.id,
            "download_path": download_path || ""
        };
        if (status == "Established") {
            setLeadFieldDisabledCond(false)
        }
        // Dispatch the payload
        dispatch(AddCallLog({ fieldData: payload, status: status }));
    }

    return (
        <div className="d-flex justify-content-center">
            <div className="min-w-700px d-flex flex-column gap-3">
                <div>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            // connectAndRegister({
                            //     username: username,
                            //     password: password,
                            // });
                        }}
                    >
                        <div className="d-flex flex-column gap-3">

                            {/* {connectStatus !== CONNECT_STATUS.CONNECTED ? (
                                <button
                                    type="submit"
                                    className="btn btn-outline-primary btn-sm"
                                >
                                    Connect
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        sessionManager?.disconnect();
                                    }}
                                    className="btn btn-outline-danger btn-sm"
                                >
                                    Disconnect
                                </button>
                            )} */}
                        </div>
                    </form>
                </div>
                <div>
                    <div className="mt-2 d-flex align-items-center gap-2">
                        <div
                            className={`rounded-circle p-1 ${connectStatus === CONNECT_STATUS.CONNECTED ? "bg-success" : "bg-warning"}`}
                        >
                            <div className={`rounded-circle ${connectStatus === CONNECT_STATUS.CONNECTED ? "bg-success" : "bg-warning"}`} style={{ height: '5px', width: '5px' }}></div>
                        </div>
                        <p className="small text-muted mb-0">
                            Connect Status: {connectStatus}
                        </p>
                    </div>
                    <div className="mt-2 d-flex align-items-center gap-2">
                        <div
                            className={`rounded-circle p-1 ${registerStatus === RegisterStatus.REGISTERED ? "bg-success" : "bg-warning"}`}
                        >
                            <div className={`rounded-circle ${registerStatus === RegisterStatus.REGISTERED ? "bg-success" : "bg-warning"}`} style={{ height: '5px', width: '5px' }}></div>
                        </div>
                        <p className="small text-muted mb-0">
                            Register Status: {registerStatus}
                        </p>
                    </div>
                </div>

                <form>
                    <div className="d-flex flex-column gap-3">
                        {/* <label>Make the new call</label>
                        <input
                            value={callTo}
                            type="text"
                            className="form-control"
                            disabled
                        /> */}

                        <button
                            onClick={async (e) => {
                                e.preventDefault();
                                const options = {
                                    media: {
                                        constraints: { audio: true, video: false }, // Specify audio only
                                        codecPreferences: ["audio/PCMU", "audio/PCMA"], // ulaw (PCMU) and alaw (PCMA)
                                    },
                                };
                                await sessionManager?.call(
                                    `sip:${callTo}@${allData?.call_provider?.domain}`,
                                    options
                                );
                                handleStartCall()
                            }}
                            className="btn btn-outline-primary btn-sm"
                        >
                            Call
                        </button>
                    </div>
                </form>
                <div className="d-flex flex-1 justify-content-center">
                    <ul className="list-unstyled">
                        {Object.keys(sessions).at(-1) && (
                            <CallSessionItem key={Object.keys(sessions).at(-1)} sessionId={Object.keys(sessions).at(-1)} handleStartCall={handleStartCall} />
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};
