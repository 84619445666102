import * as url from "../constant/Endpoints";
import axios from "axios";
import { toast } from "react-toastify";
import { objectToQueryString } from "helper/urlquerystring";
import { AES, enc } from "crypto-js";
import "react-toastify/dist/ReactToastify.css";
import {
  FetchGetAllTags,
  FetchAllRoleView as FetchAllRoleViewAgain,
  FetchAllEmailTemplates,
  FetchAllEmailSettings,
  FetchCascadeAll,
  FetchAllTeams,
  FetchCallActivity,
  FetchAllCallScript,
  FetchSmSSettings,
  FetchAllCompaigns,
  FetchOfflineCallsData,
  FetchAllActivitiesOfCamp,
  FetchAllWhatsappTemplates,
  FetchWhatsappChannel,
  StartActivityProcess,
  FetchLeadAgainHistory,
  FetchActivityDetails,
  AllActivityLeadNotes,
} from "store/action/index";
import { dispatch } from "store/index";
import { isAuthenticated, toastStyle } from "helper/helper";
const CryptoJS = require("crypto-js");

export default {
  fetchLoginUser: (payload, cb) => {
    if (payload.email === "" || payload.password === "") {
      payload.setLoading(false);

      return false;
    }

    const data = {
      email: payload.email,
      password: payload.password,
    };

    PostData(url.base_url + url.login_api_route, data)
      .then((res) => {
        const encryptedToken = CryptoJS.AES.encrypt(
          res.data.data.token,
          url.encryptionSecret
        ).toString();

        const encryptedLoginData = CryptoJS.AES.encrypt(
          JSON.stringify(res?.data?.data),
          url.encryptionSecret
        ).toString();
        localStorage.setItem("token", encryptedToken);
        localStorage.setItem("Id", res.data.data.id);
        localStorage.setItem("data", encryptedLoginData);
        // window.location.reload();
        // window.location.href = "/";
        if (res?.data?.data?.role_name == "agent") {
          window.location.reload();
          window.location.href = "/activities/call-manager";
        } else {
          window.location.reload();
          window.location.href = "/";
        }
        toast.success(res?.data?.message, toastStyle);
        payload.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthorised.") {
          toast.error("Invalid Email or Password", toastStyle);
        }
        isAuthenticated(err?.message);
        payload.setLoading(false);
        cb(err?.response?.data?.success);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
  ForgetPassword: (payload, cb) => {
    if (payload.email === "") {
      payload.setLoading(false);
      return false;
    }

    const data = {
      email: payload.email,
    };

    PostData(url.base_url + url.ForgetPassword, data)
      .then((res) => {
        toast.success(res?.data?.message, toastStyle);

        payload.setLoading(false);
        setTimeout(() => {
          localStorage.setItem("email-forget", payload.email);
          window.location = "/otp-password";
        }, 1000);
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload.setLoading(false);
        cb(err?.response?.data?.success);
        toast.error(err?.response?.data?.message, toastStyle);
      });
  },
  VerifyOTP: (payload, cb) => {
    if (
      payload.email === "" ||
      payload.password === "" ||
      payload.c_password === ""
    ) {
      payload.setLoading(false);

      return false;
    }
    if (payload.password !== payload.c_password) {
      toast.error("New Password & Confirm Password does not match", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        bodyClassName: "toastStyle",
      });
      payload.setLoading(false);
      return false;
    }

    const data = {
      email: payload.email,
      otp: payload.otp,
      password: payload.password,
      c_password: payload.c_password,
    };

    PostData(url.base_url + url.VerifyOTP, data)
      .then((res) => {
        toast.success(res?.data?.message, toastStyle);

        localStorage.removeItem("email-forget");
        payload.setLoading(false);
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload.setLoading(false);
        cb(err?.response?.data?.success);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CreateAddUsers: (payload, cb) => {
    PostRegisterData(url.base_url + url.createUsers, payload?.payload)
      .then((res) => {
        toast.success("Users Added Successfully", toastStyle);
        payload?.setLoading(false);
        cb(res.data);
        window.location = "/users/all-users";
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },
  EditUpdateUsers: (payload, cb) => {
    PostRegisterData(url.base_url + url.updateUsers, payload?.payload)
      .then((res) => {
        toast.success("Users Added Successfully", toastStyle);
        payload?.setLoading(false);
        cb(res.data);
        // window.location = "/users/all-users";
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  getLeadNotesActivities: (payload, cb) => {
    getData(url.base_url + url.notes_api + objectToQueryString(payload?.params))
      .then((res) => {
        if (payload.setLoading) {
          payload?.setLoading(false);
        }
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        if (payload.setLoading) {
          payload?.setLoading(false);
        }
        cb(err?.response?.status);
      });
  },

  GetAllUsers: (payload, cb) => {
    getData(
      url.base_url + url.getAllUsers + objectToQueryString(payload?.params)
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  CreateFetchRolee: (payload, cb) => {
    const data = payload?.payload;

    PostRegisterData(url.base_url + url.registerRollsApi, data)
      .then((res) => {
        cb(res.data);
        toast.success("Role Created Successfully", toastStyle);
        dispatch(FetchAllRoleViewAgain());
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        cb(err?.response?.status);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
  EditRole: (payload, cb) => {
    const data = payload.payload;

    PostRegisterData(url.base_url + url.editRoleAPI, data)
      .then((res) => {
        cb(res.data);
        toast.success("Role Edited Successfully", toastStyle);
        dispatch(FetchAllRoleViewAgain());
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        cb(err?.response?.status);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  AssignPermissionsShop: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.assignpermissionsApi, data)
      .then((res) => {
        cb(res.data);
        toast.success("Permissions Assigned Successfully", toastStyle);
        // <------- permissions update localstorage -------------------->
        // let permissionUser = localStorage.getItem("data");

        // let decryptedData = null;
        // if (permissionUser) {
        //   try {
        //     // Code that may throw an exception
        //     decryptedData = AES.decrypt(
        //       permissionUser,
        //       url.encryptionSecret
        //     ).toString(enc.Utf8);
        //     // ...
        //   } catch (error) {
        //     localStorage.clear();
        //     window.location.reload();
        //   }
        // }
        // let permissionsArray = [];

        // try {
        //   permissionsArray = decryptedData && JSON.parse(decryptedData);
        // } catch (e) {}

        // <------- permissions update localstorage -------------------->

        // window.location.href = "/";
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  FetchRegisterFetchCategory: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.getAllCounts, data)
      .then((res) => {
        toast.success("Category Added Successfully", toastStyle);
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
  createLeadActivities: (payload, cb) => {
    const data = payload?.fieldData;

    JsonPostData(
      url.base_url + url.addLead_activityapi + data?.activity_id,
      data
    )
      .then((res) => {
        toast.success("Add Leads Successfully", toastStyle);
        payload?.handleNavigate?.();
        // dispatch(FetchActivityDetails({ id: res.data.id }));
        const params = {
          id: data?.activity_id,
          ...(payload?.type && { activity_id: 2 }),
        };
        dispatch(FetchCallActivity({ params }));
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  createcallLeadActivities: (payload, cb) => {
    const data = payload?.fieldData;

    JsonPostData(url.base_url + url.addCallLead_activityapi, data)
      .then((res) => {
        toast.success("Add Call Leads Successfully", toastStyle);
        payload?.handleNavigate?.();
        if (payload?.NextCall) {
          let params = { id: payload?.campaign_id, activity_id: 2 };
          dispatch(FetchCallActivity({ params }));
        }
        // window.location.reload();
        // dispatch(FetchActivityDetails({ id: res.data.id }));
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineGetCallmanager: (payload, cb) => {
    getData(
      url.base_url + url.call_manager_route + objectToQueryString(payload?.params)
    )
      .then((res) => {
        if (payload?.setLoading) {
          payload?.setLoading(false)
        }
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        if (payload?.setLoading) {
          payload?.setLoading(false)
        }
        cb(err?.response?.status);
      });
  },

  getAllActivity: (payload, cb) => {
    getData(
      url.base_url + url.callActivity + objectToQueryString(payload?.params)
    )
      // getData(url.base_url + url.callActivity + (payload?.params ? "&" + objectToQueryString(payload?.params).slice(1, payload?.params.length) : ''))
      .then((res) => {
        if (payload?.setLoading) {
          payload?.setLoading(false);
        }
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        if (payload?.setLoading) {
          payload?.setLoading(false);
        }
        cb(err?.response?.status);
      });
  },

  getAllPermissions: (payload, cb) => {
    getData(url.base_url + url.allpermissionsApi)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        cb(err?.response?.status);
      });
  },
  GetRolePermissions: (payload, cb) => {
    getData(url.base_url + url.getRolePermissionsApi + `/${payload?.roleId}`)
      .then((res) => {
        cb(res.data);
        payload.setLoading(false);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        cb(err?.response?.status);
        payload.setLoading(false);
      });
  },

  HandleEditUserApi: (payload, cb) => {
    const data = payload;

    PostRegisterData(url.base_url + url.edituserapis, data)
      .then((res) => {
        cb(res.data);
        toast.success("User Edited Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  FetchAllUsersView: (payload, cb) => {
    getData(url.base_url + url.allUsersApi + "?page=" + payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        cb(err?.response?.status);
      });
  },

  FetchUsersStatusUpdateView: (payload, cb) => {
    getData(url.base_url + url.usersStatusUpdateApi + payload)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        cb(err?.response?.status);
      });
  },

  FetchStatusChange: (payload, cb) => {
    payload?.setLoading(true);
    getData(url.base_url + url.getAllCounts + payload?.params)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        cb(err?.response?.status);
      });
    payload?.setLoading(false);
    payload?.setStatus((prevStatus) => !prevStatus);
  },

  FetchAllRoleView: (payload, cb) => {
    getData(url.base_url + url.allRoleApi)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
      });
  },

  HitCompaignsApi: (payload, cb) => {
    getData(
      url.base_url +
      url.compaigns_route_api +
      objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  FetchCheckUserOnSession: (payload, cb) => {
    getData(
      url.base_url + url.onusersessioncheckroute
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        isAuthenticated(err?.response?.data?.message);
      });
  },

  CallLogsGetApi: (payload, cb) => {
    getData(
      url.base_url + url.call_logs_get_api
      // objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  LogsGetApiAll: (payload, cb) => {
    getData(
      url.base_url + url.all_logs_get_api + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  SmSLogsGetApi: (payload, cb) => {
    getData(
      url.base_url + url.sms_logs_get_api
      // objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  EmailLogsGetApi: (payload, cb) => {
    getData(
      url.base_url + url.email_logs_get_api
      // objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  WhatsAppLogsGetApi: (payload, cb) => {
    getData(
      url.base_url + url.whatsapp_logs_get_api
      // objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  GetLeadsCallLogsApi: (payload, cb) => {
    getData(
      url.base_url + url.leadscall_logs_get_api
      // objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  allRefreshCampaignLeads: (payload, cb) => {
    getData(
      url.base_url + url.refreshCampaignLeads_api + payload?.ids
      // objectToQueryString(payload?.params)
    )
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CreateConstantOfflineCall: (payload, cb) => {
    const data = payload?.fieldData;
    PostRegisterData(url.base_url + url.oneofflinecall, data)
      .then((res) => {
        payload?.setLoadingBtn(false);
        cb(res.data);
        dispatch(FetchOfflineCallsData())
        toast.success("Update Offline Call Successfully", toastStyle);
        window.location.href = "/offline/offline-calls";
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoadingBtn(false);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },



  CreatebulkUploaderr: (payload, cb) => {
    const data = payload?.fieldData;
    console.log("payloadcheck", data)

    PostRegisterData(url.base_url + url.bulkupload_api, data)
      .then((res) => {
        payload?.setLoading(false);
        dispatch(FetchOfflineCallsData())
        cb(res.data);
        toast.success("Add Offline Calls Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoading(false);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },




  EditConstantOfflineCall: (payload, cb) => {
    const data = payload?.fieldData;
    JsonPostData(url.base_url + url.editcallllogofflinecalllog, data)
      .then((res) => {
        payload?.setLoadingBtn(false);
        cb(res.data);
        dispatch(FetchOfflineCallsData())
        toast.success("Update Offline Call Successfully", toastStyle);
        window.location.href = "/offline/offline-calls";
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoadingBtn(false);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineCreateCampaign: (payload, cb) => {
    const data = payload?.fieldData;

    PostRegisterData(url.base_url + url.compaigns_route_api, data)
      .then((res) => {
        cb(res.data);
        toast.success("Create Campaign Successfully", toastStyle);
        payload?.setLoadingBtn(false);
        if (payload.isNavigate) {
          payload.isNavigate();
        }
        dispatch(FetchAllCompaigns());
        if (payload?.onClose) {
          payload?.onClose();
        }
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoadingBtn(false);

        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  GetAllLeads: (payload, cb) => {
    getData(
      url.base_url + url.get_all_leads + objectToQueryString(payload?.params)
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
      })
      .catch((err) => {
        cb(err?.response?.status);
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoading(false);

        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineDetailLeadsAllData: (payload, cb) => {
    getData(url.base_url + url.leads_details + payload?.id)
      .then((res) => {
        payload?.setLoadingD(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoadingD(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineCreateLeads: (payload, cb) => {
    const data = payload?.fieldData;

    PostRegisterData(url.base_url + url.get_all_leads, data)
      .then((res) => {
        cb(res.data);
        toast.success("Create Tags Successfully", toastStyle);
        payload?.setLoadingBtn(false);
        if (payload.isNavigate) {
          payload.isNavigate();
        }
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        payload?.setLoadingBtn(false);
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineEditLeads: (payload, cb) => {
    JsonPostData(
      url.base_url + url.get_all_leads + `/${payload?.fieldData?.id}`,
      payload?.fieldData
    )
      .then((res) => {
        cb(res.data);
        // dispatch(FetchAllTeams());
        payload?.setLoadingBtn(false);
        toast.success("Updated Lead Successfully", toastStyle);
        if (payload.isNavigate) {
          payload.isNavigate();
        }
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        payload?.setLoadingBtn(false);
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineSendEmailLeads: (payload, cb) => {
    console.log(payload);
    JsonPostData(
      url.base_url +
      url.send_email_lead_route +
      payload?.activity_id +
      "/" +
      payload?.lead_id +
      objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setSmsMessages([])
        payload?.setWhatsappMessages([])
        payload?.setEmailMessages([])
        dispatch(FetchLeadAgainHistory({ lead_id: payload?.lead_id, params: { page: "1" }, callScriptCondOff: true, setChatLoading: payload?.setChatLoading }));
        cb(res.data);
        payload?.setIsSending(false);
        toast.success(`${payload?.type} ${"Send Successfully"}`, toastStyle);
      })
      .catch((err) => {
        cb(err?.response?.status);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        payload?.setIsSending(false);
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },


  FetchOfflineCallsData: (payload, cb) => {
    getData(
      url.base_url + url.callofflineget + objectToQueryString(payload?.params)
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false)
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false)
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
  CallEngineTags: (payload, cb) => {
    getData(
      url.base_url + url.get_all_tags + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineCrTags: (payload, cb) => {
    const data = payload?.fieldData;

    PostRegisterData(url.base_url + url.get_all_tags, data)
      .then((res) => {
        cb(res.data);
        dispatch(FetchGetAllTags());
        payload?.setLoadingBtn(false);
        toast.success("Create Tags Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        payload?.setLoadingBtn(false);
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineEditTags: (payload, cb) => {
    const data = payload?.fieldData;

    PostRegisterData(
      url.base_url + url.get_all_tags + `/${payload?.fieldData?.id}`,
      data
    )
      .then((res) => {
        cb(res.data);
        dispatch(FetchGetAllTags());
        payload?.setLoadingBtn(false);
        toast.success("Create Tags Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        payload?.setLoadingBtn(false);
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  // _______ ACTIVITIES DATA _________________
  CallEngineActivities: (payload, cb) => {
    getData(
      url.base_url + url.all_activities + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
  // _______ ACTIVITIES DATA _________________

  // __________ EMAIL TEMPLATES ________________
  CallEngineEmailTemplates: (payload, cb) => {
    getData(
      url.base_url + url.email_templates + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
  CallEngineAddEmailTemplates: (payload, cb) => {
    const data = payload.payload;

    PostRegisterData(url.base_url + url.email_templates, data)
      .then((res) => {
        cb(res.data);
        payload?.setLoadingBtn(false);
        if (payload.handleTemplateChange) {
          payload.handleTemplateChange(res.data?.data?.id);
        }
        dispatch(FetchAllEmailTemplates());
        toast.success("Create Email Template Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoadingBtn(false);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineDeleteEmailTemplate: (payload, cb) => {
    const data = payload?.payload;

    PostRegisterData(url.base_url + url.email_templates + "/" + data?.id, data)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
        dispatch(FetchAllEmailTemplates());
        toast.success("Delete Email Template Successfully", toastStyle);
      })
      .catch((err) => {
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineAddWhatsappTemplates: (payload, cb) => {
    JsonPostData(url.base_url + url.whatsapp_templates, payload?.matcherFormat)
      .then((res) => {
        cb(res.data);
        toast.success("Create Whatsapp Template Successfully", toastStyle);
        payload?.setLoadingBtn(false);
        // if (payload?.handleNavigate) {
        // payload.handleNavigate?.();
        // }
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        payload?.setLoadingBtn(false);

        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineAddEmailSetting: (payload, cb) => {
    JsonPostData(url.base_url + url.email_setting, payload?.fieldData)
      .then((res) => {
        cb(res.data);
        dispatch(FetchAllEmailSettings());
        payload?.setLoadingBtn(false);
        toast.success("Add Email Setting Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        payload?.setLoadingBtn(false);
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineAddCallSetting: (payload, cb) => {
    JsonPostData(url.base_url + url.call_setting, payload?.fieldData)
      .then((res) => {
        cb(res.data);
        toast.success("Add Call Setting Successfully", toastStyle);
        payload?.setLoadingBtn(false);
        setTimeout(() => {
          window.location.href = "/settings/all-call-setting";
        }, 500);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        payload?.setLoadingBtn(false);
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineCreateNewWhatsappSetting: (payload, cb) => {
    JsonPostData(url.base_url + url.whtsapp_setting, payload?.fieldData)
      .then((res) => {
        cb(res.data);
        payload?.setLoadingBtn(false);
        dispatch(FetchAllEmailSettings());
        toast.success("Add Whatsapp Setting Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        payload?.setLoadingBtn(false);
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  // --------------------  activity  ----------------------
  CallEngineCreateAddActivity: (payload, cb) => {
    JsonPostData(url.base_url + url.add_activity, payload?.fieldData)
      .then((res) => {
        cb(res.data);
        payload?.setLoading(false);
        toast.success("Added Activity Successfully", toastStyle);
        if (payload.onClose) {
          payload.onClose();
        }
        dispatch(
          FetchAllActivitiesOfCamp({ id: payload?.fieldData.campaign_id })
        );
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoading(false);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineAllActivitiesOfCamp: (payload, cb) => {
    JsonPostData(url.base_url + url.campaign_activity + `/${payload.id}`)
      .then((res) => {
        cb(res.data);
        if (payload.setLoading) {
          payload?.setLoading(false);
        }
        // toast.success("Added Activity Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        if (payload.setLoading) {
          payload?.setLoading(false);
        }
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  crcLeadNotesActivities: (payload, cb) => {
    JsonPostData(url.base_url + url.notes_api, payload?.fieldData)
      .then((res) => {
        dispatch(
          AllActivityLeadNotes({
            setLoading: payload.setLoading,
            params: payload.params,
          })
        );
        cb(res.data);
        if (payload.setLoading) {
          payload?.setLoading(false);
        }
        // toast.success("Added Activity Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        if (payload.setLoading) {
          payload?.setLoading(false);
        }
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineActivityDetails: (payload, cb) => {
    getData(url.base_url + url.activity_detail + `?id=${payload.id}`)
      .then((res) => {
        cb(res.data);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        toast.error(err?.response?.data?.message, toastStyle);
      });
  },

  // ----------------- activity end ------------------------
  CallEngineFetchCreateCallscripts: (payload, cb) => {
    JsonPostData(url.base_url + url.call_script_api, payload?.fieldData)
      .then((res) => {
        cb(res.data);
        dispatch(FetchAllCallScript());
        payload?.setLoadingBtn(false);
        toast.success("Add Call Script Successfully", toastStyle);
        if (payload.setIsModalVisible) {
          payload.setIsModalVisible(false);
        }
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        payload?.setLoadingBtn(false);

        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineFetchEditCallscripts: (data, cb) => {
    UpdateData(url.base_url + url.call_script_api, data)
      .then((res) => {
        toast.success("Edit Call Script Successfully", toastStyle);
        // data?.setLoading(false);
        data?.setLoadingBtn(false);
        cb(res.data);
        if (data.setIsModalVisible) {
          data.setIsModalVisible(false);
        }
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        data?.setLoadingBtn(false);
        // data?.setLoading(false);
        cb(err?.response?.status);
      });
  },

  CallEngineLeadPostHistory: (payload, cb) => {
    getData(
      url.base_url +
      url.lead_historys +
      payload?.lead_id +
      objectToQueryString(payload?.params)
    )
      .then((res) => {
        cb(res.data);
        payload?.setChatLoading(false)
        // toast.success("Add Call Script Successfully", toastStyle);
        if (!payload?.callScriptCondOff) {
          dispatch(FetchAllCallScript());
        }
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineFetchCHannelPost: (payload, cb) => {
    getData(url.base_url + url.fecthChannels)
      .then((res) => {
        cb(res.data);
        dispatch(FetchAllWhatsappTemplates());
        dispatch(FetchWhatsappChannel());
        toast.success("Sync Successfully", toastStyle);
        payload?.setLoading(false);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        payload?.setLoading(false);
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineFetchCascadeesCahnnels: (payload, cb) => {
    getData(url.base_url + url.fecthChannels)
      .then((res) => {
        cb(res.data);
        dispatch(FetchCascadeAll());
        toast.success("Sync Successfully", toastStyle);
        payload?.setLoading(false);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        payload?.setLoading(false);
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineFetchWhatsappTemplateSubIDbehave: (payload, cb) => {
    getData(
      url.base_url + url.whatsapptemplatesubjidagairoute + payload?.subjecdId
    )
      .then((res) => {
        cb(res.data);
        toast.success(
          "Selected Channel Again Whatsapp Template Update Successfully",
          toastStyle
        );
        dispatch(FetchAllWhatsappTemplates());
        payload?.setLoading(false);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        payload?.setLoading(false);
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineEditActivities: (payload, cb) => {
    JsonPostData(
      url.base_url + url.edit_activities + payload?.fieldData?.activity_id,
      payload?.fieldData
    )
      .then((res) => {
        cb(res);
        payload?.setLoadingBtn(false);
        // if (!payload.isCall) {
        //   dispatch(StartActivityProcess({ activity_id: res?.data?.id }));
        // }
        if (payload?.isNavigate) {
          payload?.isNavigate();
        }
        toast.success("Edit Activity Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoadingBtn(false);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineAddCallLogs: (payload, cb) => {
    JsonPostData(url.base_url + url.call_log, payload?.fieldData)
      .then((res) => {
        cb(res.data);
        dispatch(
          FetchCallActivity({
            params: {
              activity_id: 2,
              id: payload?.fieldData?.current_activity_id,
            },
            setLoading: () => { },
          })
        );
        if (payload?.status == "Terminated") {
          // window.location.reload();
        }
        toast.success("Call Log Get Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineActivityProcess: (payload, cb) => {
    JsonPostData(url.base_url + url.process_activities + payload?.activity_id)
      .then((res) => {
        cb(res.data);
        payload?.setLoadingBtnStart(false);
        if (payload?.isNavigate) {
          payload?.isNavigate();
        }
        toast.success("Edit Activity Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoadingBtnStart(false);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineAddWhatsAppSetting: (payload, cb) => {
    getData(
      url.base_url + url.whtsapp_setting + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineCreateSmsSettings: (payload, cb) => {
    const data = payload?.fieldData;

    PostRegisterData(url.base_url + url.sms_setting, data)
      .then((res) => {
        cb(res.data);
        dispatch(FetchSmSSettings());
        payload?.setLoadingBtn(false);
        toast.success("Create SMS Setting Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        payload?.setLoadingBtn(false);
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineEditSmsSettings: (payload, cb) => {
    JsonPostData(url.base_url + url.sms_setting, payload?.fieldData)
      .then((res) => {
        cb(res.data);
        dispatch(FetchSmSSettings());
        payload?.setLoadingBtn(false);
        toast.success("Edit SMS Setting Successfully", toastStyle);
        if (payload.isNavigate) {
          payload.isNavigate();
        }
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        payload?.setLoadingBtn(false);
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineSmsSettings: (payload, cb) => {
    getData(
      url.base_url + url.sms_setting + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  SmsTemplates: (payload, cb) => {
    getData(
      url.base_url + url.sms_templates_api
      // + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  SmsCrcTemplates: (payload, cb) => {
    PostRegisterData(url.base_url + url.sms_templates_api, payload?.payload)
      .then((res) => {
        toast.success("Users Added Successfully", toastStyle);
        payload?.setLoading(false);
        payload?.setLoadingBtn(false);
        cb(res.data);
        // window.location = "/users/all-users";
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoading(false);
        payload?.setLoadingBtn(false);
        cb(err?.response?.status);
      });
  },

  SmsEditTemplates: (payload, cb) => {
    UpdateData(url.base_url + url.sms_templates_api, payload?.payload)
      .then((res) => {
        toast.success("Users Added Successfully", toastStyle);
        payload?.setLoading(false);
        payload?.setLoadingBtn(false);
        cb(res.data);
        // window.location = "/users/all-users";
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoading(false);
        payload?.setLoadingBtn(false);
        cb(err?.response?.status);
      });
  },

  // Teams

  CallEngineAllTeams: (payload, cb) => {
    getData(url.base_url + url.teams + objectToQueryString(payload?.params))
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineFetchCallscripts: (payload, cb) => {
    getData(
      url.base_url + url.call_script_api + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
  CallEngineCasdeGetAll: (payload, cb) => {
    getData(
      url.base_url + url.fetchcascades + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineGetWhatsappChannel: (payload, cb) => {
    getData(
      url.base_url +
      url.channel_get_api_route +
      objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineCreateTeams: (payload, cb) => {
    JsonPostData(url.base_url + url.teams, payload?.fieldData)
      .then((res) => {
        cb(res.data);
        dispatch(FetchAllTeams());
        toast.success("Added Team Successfully", toastStyle);
        payload?.setLoadingBtn(false);
        if (payload.isNavigate) {
          payload.isNavigate();
        }
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        payload?.setLoadingBtn(false);
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
  CallEngineUpdateTeams: (payload, cb) => {
    JsonPostData(
      url.base_url + url.teams + `/${payload?.fieldData?.id}`,
      payload?.fieldData
    )
      .then((res) => {
        cb(res.data);
        dispatch(FetchAllTeams());
        payload?.setLoadingBtn(false);
        toast.success("Updated Team Successfully", toastStyle);
        if (payload.isNavigate) {
          payload.isNavigate();
        }
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        payload?.setLoadingBtn(false);
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
  //  Teams

  CallEngineUpdateEmailSetting: (payload, cb) => {
    JsonPostData(
      url.base_url + url.email_setting + "/" + payload?.fieldData?.id || "",
      payload?.fieldData
    )
      .then((res) => {
        cb(res.data);
        payload?.setLoadingBtn(false);
        dispatch(FetchAllEmailSettings());
        toast.success("Update Email Setting Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        payload?.setLoadingBtn(false);
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineUpdateCallSetting: (payload, cb) => {
    JsonPostData(
      url.base_url + url.call_setting + "/" + payload?.fieldData?.id || "",
      payload?.fieldData
    )
      .then((res) => {
        cb(res.data);
        toast.success("Update Call Setting Successfully", toastStyle);
        payload?.setLoadingBtn(false);
        setTimeout(() => {
          window.location.href = "/settings/all-call-setting";
        }, 500);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoadingBtn(false);
        toast.error(err?.response?.data?.message, toastStyle);
      });
  },

  CallEngineEditEmailTemplates: (payload, cb) => {
    const data = payload?.payload;

    PostRegisterData(url.base_url + url.email_templates + `/${data.id}`, data)
      .then((res) => {
        cb(res.data);
        payload?.setLoadingBtn(false);
        dispatch(FetchAllEmailTemplates());
        toast.success("Updated Email Template Successfully", toastStyle);
      })
      .catch((err) => {
        isAuthenticated(err?.response?.data?.message);
        payload?.setLoadingBtn(false);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
  // __________ WHSTAPP TEMPLATES ________________

  CallEngineWhatsappTemplates: (payload, cb) => {
    getData(
      url.base_url +
      url.whatsapp_templates +
      objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  allDashboardCounts: (payload, cb) => {
    getData(url.base_url + url.dashboardcounts_api)
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineEmailSettings: (payload, cb) => {
    getData(
      url.base_url + url.email_setting + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },

  CallEngineCallSettings: (payload, cb) => {
    getData(
      url.base_url + url.call_setting + objectToQueryString(payload?.params)
    )
      .then((res) => {
        payload?.setLoading(false);
        cb(res.data);
      })
      .catch((err) => {
        cb(err?.response?.status);
        payload?.setLoading(false);
        isAuthenticated(err?.response?.data?.message);
        const errors = err?.response?.data?.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          const errorMessages = errors[firstErrorKey];
          toast.error(
            Array.isArray(errorMessages)
              ? errorMessages[0]
              : errorMessages || "Something went wrong",
            toastStyle
          );
        }
      });
  },
};

function PostData(URL, data) {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(URL, data, config);
}
export function JsonPostData(URL, data) {
  let getNewTokenn = localStorage.getItem("token");
  let NewToekn = "";
  if (getNewTokenn) {
    NewToekn = CryptoJS.AES.decrypt(
      getNewTokenn,
      url.encryptionSecret
    ).toString(CryptoJS.enc.Utf8);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${NewToekn}`,
    },
  };
  return axios.post(URL, data, config);
}
function PostRegisterData(URL, data) {
  let getNewTokenn = localStorage.getItem("token");
  let NewToekn = "";
  if (getNewTokenn) {
    NewToekn = CryptoJS.AES.decrypt(
      getNewTokenn,
      url.encryptionSecret
    ).toString(CryptoJS.enc.Utf8);
  }
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${NewToekn}`,
    },
  };
  return axios.post(URL, data, config);
}
function UpdateData(URL, data) {
  let getNewTokenn = localStorage.getItem("token");
  let NewToekn = "";
  if (getNewTokenn) {
    NewToekn = CryptoJS.AES.decrypt(
      getNewTokenn,
      url.encryptionSecret
    ).toString(CryptoJS.enc.Utf8);
  }
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${NewToekn}`,
    },
  };
  return axios.put(`${URL}/${data.id}`, data, config);
}

function getData(URL) {
  let getNewTokenn = localStorage.getItem("token");
  let NewToekn = "";
  if (getNewTokenn) {
    NewToekn = CryptoJS.AES.decrypt(
      getNewTokenn,
      url.encryptionSecret
    ).toString(CryptoJS.enc.Utf8);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${NewToekn}`,
    },
  };
  return axios.get(URL, config);
}
