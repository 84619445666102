import React from 'react';

const AudioPlayer = ({ audioUrl }) => {


  return (
    <div className='mt-2'>
      <audio controls style={{ maxWidth: '220px', marginTop: "14px" }}>
        <source src={audioUrl} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>
      {/* <a href={audioUrl} target="_blank" rel="noopener noreferrer">
      <button className='btn btn-primary' style={{ backgroundColor:'#0055A2' }} >
        Play Recording
      </button>
      </a> */}
    </div>
  );
};

export default AudioPlayer;